import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {HistoricAuditListResponse} from "../../../api/rm";
import {AUDIT_LIST_HISTORIC_PAGED_STORE} from "../actions/AuditListHistoricPagedActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<HistoricAuditListResponse>(
    createBlankHistoricAuditListResponse()
);

/** Infer type of the reducer for code completion and quality */
const auditListHistoricPagedReducer = (
    state: StoreServiceData<HistoricAuditListResponse> = defaultState,
    action: ServiceActionTypes<HistoricAuditListResponse>
): StoreServiceData<HistoricAuditListResponse> =>
    createReducer(state, action, AUDIT_LIST_HISTORIC_PAGED_STORE, () =>
        showErrorToast(action.error)
    );

export default auditListHistoricPagedReducer;

export function createBlankHistoricAuditListResponse(): HistoricAuditListResponse {
    return {
        auditId: 0,
        numPerPage: 0,
        pageNum: 0,
        results: [],
        totalEntries: 0
    };
}
