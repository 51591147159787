import {Dispatch} from "redux";
import {CATEGORY_STORE, CategoryDispatchTypes} from "./CategoryActionTypes";
import {Category} from "../../../api/rm";
import RiskManagementApiModel from "../../apiModel/RiskManagementApiModel";
import {validateCategoryVenueForm} from "../../../utils/categoryVenueUtils";
import {getDataFromServiceWithRedux, postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nukes store of stale data */
export const nullifyCategoryStore = () => {
    return async (dispatch: Dispatch<CategoryDispatchTypes>) => {
        dispatch({
            type: CATEGORY_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Nukes store of stale data */
export const createNewCategory = () => {
    return async (dispatch: Dispatch<CategoryDispatchTypes>) => {
        dispatch({
            type: CATEGORY_STORE.SUCCESS,
            error: null,
            loading: false,
            data: getEmptyCategory()
        });
    };
};

export const setCategory = (category: Category) => {
    return async (dispatch: Dispatch<CategoryDispatchTypes>) => {
        dispatch({
            type: CATEGORY_STORE.SUCCESS,
            error: null,
            loading: false,
            data: category
        });
    };
};

/** Nukes store of stale data */
export const getCategory = (id: number) => {
    return async (dispatch: Dispatch<CategoryDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                CATEGORY_STORE,
                dispatch,
                () => RiskManagementApiModel.getRmApi().getCategory(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CATEGORY_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** saves category */
export const saveCategory = (category: Category) => {
    return async (dispatch: Dispatch<CategoryDispatchTypes>) => {
        try {
            const {valid} = validateCategoryVenueForm(category, "Category");
            if (!valid) return false;

            return await postDataToServiceWithRedux(
                CATEGORY_STORE,
                dispatch,
                () => RiskManagementApiModel.getRmApi().saveCategory(category),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CATEGORY_STORE.ERROR,
                error: e,
                loading: false
            });
            return false;
        }
    };
};

export function getEmptyCategory(): Category {
    return {
        id: 0,
        name: ""
    };
}

export function getCategoryName(
    categoryId: number,
    categoryList: Category[] | undefined | null
): string | undefined {
    if (!categoryList) return;
    const index = categoryList?.findIndex((category: Category) => category.id === categoryId);

    return index < 0 ? "Category deleted" : categoryList[index].name;
}
