import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {HistoricAuditListResponse} from "../../../api/rm";

export const AUDIT_LIST_HISTORIC_PAGED_STORE = createStoreState("audit_list_historic_paged");

interface AuditListHistoricPagedLoading extends StoreServiceData<HistoricAuditListResponse> {
    type: typeof AUDIT_LIST_HISTORIC_PAGED_STORE.LOADING;
}

interface AuditListHistoricPagedError extends StoreServiceData<HistoricAuditListResponse> {
    type: typeof AUDIT_LIST_HISTORIC_PAGED_STORE.ERROR;
}

interface AuditListHistoricPagedSuccess extends StoreServiceData<HistoricAuditListResponse> {
    type: typeof AUDIT_LIST_HISTORIC_PAGED_STORE.SUCCESS;
}

export type AuditListPagedDispatchTypes =
    | AuditListHistoricPagedLoading
    | AuditListHistoricPagedError
    | AuditListHistoricPagedSuccess;
