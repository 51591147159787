import React, {useEffect} from "react";
import {RiskManagementStats} from "../../../../api/rm";
import BackgroundContainer from "../../../Containers/BackgroundContainer";
import {useDispatch} from "react-redux";
import {nullifyStatsStore} from "../../../../store/stats/actions/StatsActions";
import AuditBarChart from "./BarCharts/AuditBarChart";
import ComplianceStats from "./DonutCharts/ComplianceStats";

const RiskManagementStatsWrapper = (props: RiskManagementStats) => {
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(nullifyStatsStore());
        };
    }, []);
    return (
        <React.Fragment>
            {props.totalNewAudits > 0 ? (
                <React.Fragment>
                    <BackgroundContainer background={"background-clear"}>
                        <AuditBarChart
                            chartName={"Category Audits"}
                            statsObject={props.categoryNewAudits}
                            totalNewAudits={props.totalNewAudits}
                            toolTipTextPrefix={"Number of new category audits for"}
                        />
                    </BackgroundContainer>
                    <BackgroundContainer background={"background-chevrons"}>
                        <AuditBarChart
                            chartName={"Venue Audits"}
                            statsObject={props.venueNewAudits}
                            totalNewAudits={props.totalNewAudits}
                            toolTipTextPrefix={"Number of new venue audits for"}
                        />
                    </BackgroundContainer>
                    <BackgroundContainer background={"background-clear"}>
                        <ComplianceStats {...props} />
                    </BackgroundContainer>
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 mt-5 fadeIn">
                    <div className="col">
                        <h6 className="text-center">
                            There are no stats available for this month.
                        </h6>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default RiskManagementStatsWrapper;
