import {combineReducers} from "redux";
import authReducer from "./auth/reducer/AuthReducer";
import navigationReducer from "./navigation/reducer/NavigationReducer";
import sidebarReducer from "./sidebar/reducer/sidebarReducer";
import categoryListReducer from "./categoryList/reducer/CategoryListReducer";
import categoryReducer from "./category/reducer/CategoryReducer";
import venueReducer from "./venue/reducer/VenueReducer";
import venueListReducer from "./venueList/reducer/VenueListReducer";
import auditReducer from "./audit/reducer/AuditReducer";
import auditListPagedReducer from "./auditListPaged/reducer/AuditListPagedReducer";
import auditListHistoricPagedReducer from "./auditHistoricListPaged/reducer/AuditListHistoricPagedReducer";
import historicAuditReducer from "./historicAudit/reducer/HistoricAuditReducer";
import staffListReducer from "./staffList/reducer/StaffListReducer";
import statsReducer from "./stats/reducer/StatsReducer";
import mcConfigReducer from "./mcConfig/reducer/McConfigReducer";

const RootReducer = combineReducers({
    navigation: navigationReducer,
    sidebar: sidebarReducer,
    auth: authReducer,
    mcConfig: mcConfigReducer,
    category: categoryReducer,
    categoryList: categoryListReducer,
    venue: venueReducer,
    venueList: venueListReducer,
    audit: auditReducer,
    auditListPaged: auditListPagedReducer,
    auditListHistoricPaged: auditListHistoricPagedReducer,
    historicAudit: historicAuditReducer,
    staffList: staffListReducer,
    stats: statsReducer
});

export default RootReducer;
