import React from "react";
import PrintableTableRow from "../PrintableTableRow";
import PrintableTD from "../PrintableTD";
import {RiskAssessmentMatrixRow} from "../../../../store/audit/helpers/RiskAssessment/riskAssessmentMatrixHelpers";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import MatrixRatingTableRowItem from "./MatrixRatingTableRowItem";

const PrintableRiskAssessmentTableRow = (props: RiskAssessmentMatrixRow) => {
    return (
        <PrintableTableRow rowType={"bodyRow"}>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                {props.riskDescription}
            </PrintableTD>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                {props.causeOfIdentifiedRisk}
            </PrintableTD>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                {formatUnixToDDMMYYYY(props.dateCreated)}
            </PrintableTD>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                {formatUnixToDDMMYYYY(props.lastReviewed)}
            </PrintableTD>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                {getUiFriendlyText(props.reviewTimeScale)}
            </PrintableTD>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                {formatUnixToDDMMYYYY(props.reviewDate)}
            </PrintableTD>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                {getUiFriendlyText(props.riskCategory)}
            </PrintableTD>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                {props.riskOwner.staffName}
            </PrintableTD>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                <MatrixRatingTableRowItem {...props.initialRating} />
            </PrintableTD>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                {props.initialRating.details}
            </PrintableTD>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                <MatrixRatingTableRowItem {...props.currentRating} />
            </PrintableTD>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                {props.currentRating.details}
            </PrintableTD>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                <MatrixRatingTableRowItem {...props.targetRating} />
            </PrintableTD>
            <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                {props.targetRating.details}
            </PrintableTD>
        </PrintableTableRow>
    );
};

export default PrintableRiskAssessmentTableRow;
