export const routeNames = {
    categoryList: {
        path: "/category/list",
        name: "Category List"
    },
    categoryAdd: {
        path: "/category/add",
        name: "Add Category"
    },
    categoryEdit: {
        path: "/category/edit",
        name: "Edit Category"
    },
    venueList: {
        path: "/venue/list",
        name: "Venue List"
    },
    venueAdd: {
        path: "/venue/add",
        name: "Add Venue"
    },
    venueEdit: {
        path: "/venue/edit",
        name: "Edit Venue"
    },
    auditList: {
        path: "/audit/list",
        name: "Audit List"
    },
    auditAdd: {
        path: "/audit/add",
        name: "Add Audit"
    },
    auditEdit: {
        path: "/audit/edit",
        name: "Edit Audit"
    },
    auditHistoricList: {
        path: "/audit/historic/list",
        name: "Historic Audit List"
    },
    auditHistoricView: {
        path: "/audit/historic/view",
        name: "View Historic Audit"
    },
    overview: {
        path: "/overview",
        name: "Overview"
    },
    //Auth pages
    login: {
        path: "/login",
        name: "",
        icon: ""
    },
    loggedin: {
        path: "/loggedin",
        name: "",
        icon: ""
    },
    logout: {
        path: "/logout",
        name: "",
        icon: ""
    },
    inActive: {
        path: "/inactive",
        name: "",
        icon: ""
    },
    notAuthorised: {
        path: "/not-authorised",
        name: "",
        icon: ""
    },
    notFound: {
        path: "/not-found",
        name: "",
        icon: ""
    }
};
