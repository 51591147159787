import React, {useEffect, useState} from "react";
import {pagedRequestConfig, RequestFilterProps} from "../../../Filters/helpers/filterHelpers";
import {AuditListRequest, AuditType, Category, Venue} from "../../../../api/rm";
import moment, {Moment} from "moment";
import DatePicker from "react-datepicker";
import {getAuditTypeFromString} from "../../../../utils/enumUtils";
import {useDispatch} from "react-redux";
import {nullifyAuditListPagedStore} from "../../../../store/auditListPaged/actions/AuditListPagedActions";
import GenericTypeDropdown from "../../../Dropdown/GenericTypeDropdown";
import {OptionsFromType} from "../../../Dropdown/Helpers/dropdownUtils";
import VenueDropdown from "../../../Dropdown/VenueDropdown";
import {getEmptyVenue} from "../../../../store/venue/actions/VenueActions";
import {getEmptyCategory} from "../../../../store/category/actions/CategoryActions";
import CategoryDropdown from "../../../Dropdown/CategoryDropdown";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import {capitalizeFirstLetter, decapitalizeFirstLetter} from "../../../../utils/textUtils";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../Hooks/useQuery";

const AuditListPagedFilters = (props: RequestFilterProps<AuditListRequest>) => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState<Moment>();
    const [auditType, setAuditType] = useState<string>();
    const [venue, setVenue] = useState<Venue>();
    const [pageNumber, setPageNumber] = useState<number>(-1);
    const [category, setCategory] = useState<Category>();
    const history = useHistory();
    const query = useQuery();

    useEffect(() => {
        const minDate = query.get("minDate");
        setStartDate(minDate ? moment.unix(+minDate) : moment().startOf("month"));

        const auditTypes = query.get("auditTypes");
        setAuditType(
            auditTypes ? getAuditTypeFromString(capitalizeFirstLetter(auditTypes)) : AuditType.None
        );

        const venueId = query.get("venueId");
        setVenue(venueId ? {...getEmptyVenue(), id: +venueId} : getEmptyVenue());

        const categoryId = query.get("categoryId");
        setCategory(categoryId ? {...getEmptyCategory(), id: +categoryId} : getEmptyCategory());

        const pageNum = query.get("pageNum");
        setPageNumber(pageNum ? +pageNum : 0);

        return () => {
            dispatch(nullifyAuditListPagedStore());
        };
    }, []);

    useEffect(() => {
        if (!startDate) return;
        if (!venue) return;
        if (!category) return;
        if (!auditType) return;
        if (pageNumber < 0) return;

        const venueId = venue.id > 0 ? venue.id : undefined;
        const categoryId = category.id > 0 ? category.id : undefined;
        const request = buildAuditListRequest(
            startDate,
            auditType,
            venueId,
            categoryId,
            pageNumber
        );
        buildAuditListPagedUrlHistory(request);
        props.onRequestChanged(request);
    }, [startDate, auditType, venue, category, pageNumber]);

    /** Builds request for audit list
     * ts-ignore on types variable as api expected AuditType but will accept string. Have to do a string operation to stop the service from erroring
     * */
    const buildAuditListRequest = (
        incomingStartDate: Moment,
        incomingTypes: string,
        incomingVenueId?: number,
        incomingCategoryId?: number,
        incomingPageNumber?: number
    ): AuditListRequest => {
        const minDate = incomingStartDate
            ? incomingStartDate.clone().startOf("month").unix()
            : undefined;
        const maxDate = incomingStartDate
            ? incomingStartDate.clone().endOf("month").unix()
            : undefined;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const types: AuditType[] | undefined =
            incomingTypes === AuditType.None
                ? undefined
                : [decapitalizeFirstLetter(getAuditTypeFromString(incomingTypes))];
        const pageNum = incomingPageNumber ? incomingPageNumber : 0;

        return {
            pageNum,
            numPerPage: pagedRequestConfig.resultsPerPage,
            minDate,
            maxDate,
            venueId: incomingVenueId,
            categoryId: incomingCategoryId,
            types
        };
    };

    const buildAuditListPagedUrlHistory = (request: AuditListRequest) => {
        const search: string[] = [];

        if (request.venueId) {
            search.push(`venueId=${request.venueId}`);
        }

        if (request.categoryId) {
            search.push(`categoryId=${request.categoryId}`);
        }

        if (request.types && request.types.length > 0) {
            search.push(`auditTypes=${request.types[0]}`);
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (request.pageNum >= 0) {
            search.push(`pageNum=${request.pageNum}`);
        }
        if (request.minDate) {
            search.push(`minDate=${request.minDate}`);
        }

        if (request.maxDate) {
            search.push(`maxDate=${request.maxDate}`);
        }

        history.push({
            search: search.join("&")
        });
    };

    const onAuditTypeChanged = (type?: string | number) => {
        if (!type) return;
        setAuditType(type.toString());
    };

    const onVenueChanged = (incomingVenue: Venue | undefined) => {
        if (!venue) return;
        const newVenue: Venue = {
            ...venue,
            id: incomingVenue ? incomingVenue.id : 0
        };
        setVenue(newVenue);
    };

    const onCategoryChanged = (incomingCategory: Category | undefined) => {
        if (!category) return;
        const newCategory: Category = {
            ...category,
            id: incomingCategory ? incomingCategory.id : 0
        };
        setCategory(newCategory);
    };

    const onDateChanged = (date: Date) => {
        setStartDate(moment(date));
    };

    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <div className="filter-item">
                    <h6>Category</h6>
                    {category && (
                        <CategoryDropdown
                            item={category}
                            changeOption={onCategoryChanged}
                            searchable
                            clearable
                        />
                    )}
                </div>
                <div className="filter-item">
                    <h6>Venue</h6>
                    {venue && (
                        <VenueDropdown
                            item={venue}
                            changeOption={onVenueChanged}
                            searchable
                            clearable
                        />
                    )}
                </div>
                <div className="filter-item">
                    <h6>Month</h6>
                    <DatePicker
                        selected={startDate?.toDate()}
                        onChange={onDateChanged}
                        showMonthYearPicker
                        dateFormat="MMMM yyyy"
                        portalId="root-portal"
                        customInput={<DatePickerInputButton />}
                        placeholderText="Select start date"
                    />
                </div>
                <div className="filter-item">
                    <h6>Audit Type</h6>
                    {auditType && (
                        <GenericTypeDropdown
                            searchWithDecapitalisedFirstLetter={false}
                            enumOptions={AuditTypeForFilters}
                            splitByCapitalLetter={true}
                            clearable={false}
                            searchable={true}
                            changeOption={onAuditTypeChanged}
                            id={auditType}
                            disabled={false}
                            getOptionsFrom={OptionsFromType.Value}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default AuditListPagedFilters;

// eslint-disable-next-line no-shadow
enum AuditTypeForFilters {
    None = "None",
    RiskAssessmentMatrix = "RiskAssessmentMatrix",
    AuditPremisesDepot = "AuditPremisesDepot",
    AuditPremisesHeadOffice = "AuditPremisesHeadOffice",
    AuditPremisesTrainingStorageArea = "AuditPremisesTrainingStorageArea",
    AuditWeeklyFireSafety = "AuditWeeklyFireSafety",
    AuditMonthlyFireSafety = "AuditMonthlyFireSafety",
    AuditBiAnnualFireSafety = "AuditBiAnnualFireSafety",
    AuditAnnualFireSafety = "AuditAnnualFireSafety",
    AuditFiveYearlyFireSafety = "AuditFiveYearlyFireSafety"
}
