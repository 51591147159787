import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {Category} from "../../../api/rm";
import {CATEGORY_STORE} from "../actions/CategoryActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<Category>(null);

/** Infer type of the reducer for code completion and quality */
const categoryReducer = (
    state: StoreServiceData<Category> = defaultState,
    action: ServiceActionTypes<Category>
): StoreServiceData<Category> =>
    createReducer(state, action, CATEGORY_STORE, () => showErrorToast(action.error));

export default categoryReducer;
