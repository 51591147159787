import {StaffLink} from "../../../../components/Dropdown/Helpers/dropdownUtils";
import moment from "moment";
import {nanoid} from "nanoid";
import {showErrorToast} from "../../../../utils/toastUtils";
import {AuditForm} from "../auditHelpers";

export interface RiskAssessmentMatrix {
    rows: RiskAssessmentMatrixRow[];
}

export interface RiskAssessmentMatrixRow {
    id: string;
    riskAssessmentRowVersion: number;
    riskDescription: string;
    causeOfIdentifiedRisk: string;
    dateCreated: number;
    reviewTimeScale: ReviewTimeScale;
    lastReviewed: number;
    reviewDate: number;
    riskCategory: RiskCategory;
    riskOwner: StaffLink;
    initialRating: MatrixRating;
    currentRating: MatrixRating;
    targetRating: MatrixRating;
}

// eslint-disable-next-line no-shadow
export enum ReviewTimeScale {
    WeeklyFromLastReview = "WeeklyFromLastReview",
    MonthlyFromLastReview = "MonthlyFromLastReview",
    TwoMonthlyFromLastReview = "TwoMonthlyFromLastReview",
    QuarterlyFromLastReview = "QuarterlyFromLastReview"
}

// eslint-disable-next-line no-shadow
export enum RiskCategory {
    HealthAndSafety = "HealthAndSafety",
    Clinical = "Clinical",
    Operational = "Operational",
    Financial = "Financial",
    InfectionControl = "InfectionControl",
    HumanResources = "HumanResources"
}

export interface SeverityLikelihoodMatrix {
    severity: number;
    likelihood: number;
}

export interface MatrixRating {
    severityLikelihoodMatrix: SeverityLikelihoodMatrix;
    details: string;
}

export function generateBlankRiskAssessment(): RiskAssessmentMatrix {
    return {
        rows: []
    };
}

export function createBlankRiskAssessmentRow(): RiskAssessmentMatrixRow {
    const now = moment().startOf("day").unix();
    const timeScale = ReviewTimeScale.WeeklyFromLastReview;
    return {
        id: nanoid(),
        riskAssessmentRowVersion: 0,
        causeOfIdentifiedRisk: "",
        dateCreated: now,
        lastReviewed: now,
        reviewDate: getUpdatedReviewDateFromTimeScale(timeScale, now),
        reviewTimeScale: timeScale,
        riskCategory: RiskCategory.HealthAndSafety,
        riskDescription: "",
        riskOwner: {
            staffId: "",
            staffName: ""
        },
        initialRating: generateBlankMatrixRating(),
        currentRating: generateBlankMatrixRating(),
        targetRating: generateBlankMatrixRating()
    };
}

function generateBlankMatrixRating(): MatrixRating {
    return {
        details: "",
        severityLikelihoodMatrix: {
            severity: 0,
            likelihood: 0
        }
    };
}

export function getRiskCategoryFromString(value: string): RiskCategory {
    return RiskCategory[value as keyof typeof RiskCategory];
}

export function getTimeScaleForReviewFromString(value: string): ReviewTimeScale {
    return ReviewTimeScale[value as keyof typeof ReviewTimeScale];
}

export function getUpdatedReviewDateFromTimeScale(
    timeScale: ReviewTimeScale,
    lastReviewed: number
): number {
    switch (timeScale) {
        case ReviewTimeScale.WeeklyFromLastReview:
            return moment.unix(lastReviewed).startOf("day").add(1, "week").unix();
        case ReviewTimeScale.MonthlyFromLastReview:
            return moment.unix(lastReviewed).startOf("day").add(1, "month").unix();
        case ReviewTimeScale.TwoMonthlyFromLastReview:
            return moment.unix(lastReviewed).startOf("day").add(2, "month").unix();
        case ReviewTimeScale.QuarterlyFromLastReview:
            return moment.unix(lastReviewed).startOf("day").add(1, "quarter").unix();
    }
}

export function validateRiskAssessmentMatrix(matrix: RiskAssessmentMatrix): boolean {
    let valid = true;
    const messages: string[] = [];
    for (const row of matrix.rows) {
        if (row.riskDescription === "" || !row.riskDescription) {
            messages.push("One or more risk descriptions are invalid.");
            valid = false;
        }
        if (row.causeOfIdentifiedRisk === "" || !row.causeOfIdentifiedRisk) {
            messages.push("One or more cause/source of identified risks are invalid.");
            valid = false;
        }

        if (row.lastReviewed < row.dateCreated) {
            messages.push(
                "The last reviewed date cannot be before the creation date of a risk assessment."
            );
            valid = false;
        }

        if (row.riskOwner.staffId.length === 0 || row.riskOwner.staffName.length === 0) {
            messages.push("One or more risk assessments don't have risk owners.");
            valid = false;
        }

        if (
            row.initialRating.severityLikelihoodMatrix.severity === 0 ||
            row.initialRating.severityLikelihoodMatrix.severity > 5
        ) {
            messages.push("One or more initial rating severity values are invalid.");
            valid = false;
        }

        if (
            row.initialRating.severityLikelihoodMatrix.likelihood === 0 ||
            row.initialRating.severityLikelihoodMatrix.likelihood > 5
        ) {
            messages.push("One or more initial rating likelihood values are invalid.");
            valid = false;
        }

        if (
            row.currentRating.severityLikelihoodMatrix.severity === 0 ||
            row.currentRating.severityLikelihoodMatrix.severity > 5
        ) {
            messages.push("One or more current rating severity values are invalid.");
            valid = false;
        }

        if (
            row.currentRating.severityLikelihoodMatrix.likelihood === 0 ||
            row.currentRating.severityLikelihoodMatrix.likelihood > 5
        ) {
            messages.push("One or more current rating likelihood values are invalid.");
            valid = false;
        }

        if (
            row.targetRating.severityLikelihoodMatrix.severity === 0 ||
            row.targetRating.severityLikelihoodMatrix.severity > 5
        ) {
            messages.push("One or more target rating severity values are invalid.");
            valid = false;
        }

        if (
            row.targetRating.severityLikelihoodMatrix.likelihood === 0 ||
            row.targetRating.severityLikelihoodMatrix.likelihood > 5
        ) {
            messages.push("One or more target rating likelihood values are invalid.");
            valid = false;
        }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const uniqueMessages = [...new Set(messages)];

    for (const message of uniqueMessages) {
        showErrorToast(message);
    }
    return valid;
}

/** Updates row version. 0 = fields can be edited, > 0 means read only */
export function updateRiskAssessmentRowVersion(auditForm: AuditForm): AuditForm {
    if (!auditForm.riskAssessmentMatrixAudit) return auditForm;
    for (const row of auditForm.riskAssessmentMatrixAudit.rows) {
        row.riskAssessmentRowVersion += 1;
    }
    return auditForm;
}
