/* tslint:disable */
/* eslint-disable */
/**
 * Medicare RM
 * API for Medicare Risk Management
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * A generic audit record
 * @export
 * @interface Audit
 */
export interface Audit {
    /**
     * Unique ID for the audit
     * @type {number}
     * @memberof Audit
     */
    'id': number;
    /**
     * ID of the category this audit is within
     * @type {number}
     * @memberof Audit
     */
    'categoryId': number;
    /**
     * ID of the venue this audit is within
     * @type {number}
     * @memberof Audit
     */
    'venueId': number;
    /**
     * Date of the audit
     * @type {number}
     * @memberof Audit
     */
    'dateCreated': number;
    /**
     * 
     * @type {AuditType}
     * @memberof Audit
     */
    'type': AuditType;
    /**
     * 0-100 score for compliance
     * @type {number}
     * @memberof Audit
     */
    'complianceScore'?: number;
    /**
     * Username of the author
     * @type {string}
     * @memberof Audit
     */
    'authorId': string;
    /**
     * Name of the author
     * @type {string}
     * @memberof Audit
     */
    'authorName': string;
    /**
     * Raw JSON for the audit itself, stored as-is
     * @type {string}
     * @memberof Audit
     */
    'payload': string;
}
/**
 * Head information for an audit
 * @export
 * @interface AuditListEntry
 */
export interface AuditListEntry {
    /**
     * Unique ID of the audit
     * @type {number}
     * @memberof AuditListEntry
     */
    'id': number;
    /**
     * Date the audit was created
     * @type {number}
     * @memberof AuditListEntry
     */
    'dateCreated': number;
    /**
     * 
     * @type {AuditType}
     * @memberof AuditListEntry
     */
    'type': AuditType;
    /**
     * Username of the author
     * @type {string}
     * @memberof AuditListEntry
     */
    'authorId': string;
    /**
     * Name of the author
     * @type {string}
     * @memberof AuditListEntry
     */
    'authorName': string;
    /**
     * Category ID
     * @type {number}
     * @memberof AuditListEntry
     */
    'categoryId': number;
    /**
     * Name of the category
     * @type {string}
     * @memberof AuditListEntry
     */
    'categoryName': string;
    /**
     * Venue ID
     * @type {number}
     * @memberof AuditListEntry
     */
    'venueId': number;
    /**
     * Name of the venue
     * @type {string}
     * @memberof AuditListEntry
     */
    'venueName': string;
}
/**
 * Request a list of audits that meet the provided criteria
 * @export
 * @interface AuditListRequest
 */
export interface AuditListRequest {
    /**
     * Page to get, starting at 0
     * @type {number}
     * @memberof AuditListRequest
     */
    'pageNum'?: number;
    /**
     * Maximum number of entries to fetch per page
     * @type {number}
     * @memberof AuditListRequest
     */
    'numPerPage'?: number;
    /**
     * Minimum creation date, inclusive
     * @type {number}
     * @memberof AuditListRequest
     */
    'minDate'?: number;
    /**
     * Maximum creation date, exclusive
     * @type {number}
     * @memberof AuditListRequest
     */
    'maxDate'?: number;
    /**
     * Category ID to filter to
     * @type {number}
     * @memberof AuditListRequest
     */
    'categoryId'?: number;
    /**
     * Venue ID to filter to
     * @type {number}
     * @memberof AuditListRequest
     */
    'venueId'?: number;
    /**
     * Audit types to filter to
     * @type {Array<AuditType>}
     * @memberof AuditListRequest
     */
    'types'?: Array<AuditType>;
}
/**
 * Response to an audit list request
 * @export
 * @interface AuditListResponse
 */
export interface AuditListResponse {
    /**
     * Page to get, starting at 0
     * @type {number}
     * @memberof AuditListResponse
     */
    'pageNum': number;
    /**
     * Maximum number of entries to fetch per page
     * @type {number}
     * @memberof AuditListResponse
     */
    'numPerPage': number;
    /**
     * Minimum creation date, inclusive
     * @type {number}
     * @memberof AuditListResponse
     */
    'minDate'?: number;
    /**
     * Maximum creation date, exclusive
     * @type {number}
     * @memberof AuditListResponse
     */
    'maxDate'?: number;
    /**
     * Category ID to filter to
     * @type {number}
     * @memberof AuditListResponse
     */
    'categoryId'?: number;
    /**
     * Venue ID to filter to
     * @type {number}
     * @memberof AuditListResponse
     */
    'venueId'?: number;
    /**
     * Audit types to filter to
     * @type {Array<AuditType>}
     * @memberof AuditListResponse
     */
    'types'?: Array<AuditType>;
    /**
     * Total number of entries that meet the specified criteria
     * @type {number}
     * @memberof AuditListResponse
     */
    'totalEntries': number;
    /**
     * Entries for this page, can be empty
     * @type {Array<AuditListEntry>}
     * @memberof AuditListResponse
     */
    'results': Array<AuditListEntry>;
}
/**
 * Type of audit / record
 * @export
 * @enum {string}
 */

export enum AuditType {
    RiskAssessmentMatrix = 'RiskAssessmentMatrix',
    AuditPremisesDepot = 'AuditPremisesDepot',
    AuditPremisesHeadOffice = 'AuditPremisesHeadOffice',
    AuditPremisesTrainingStorageArea = 'AuditPremisesTrainingStorageArea',
    AuditFireSafety = 'AuditFireSafety',
    None = 'None',
    AuditWeeklyFireSafety = 'AuditWeeklyFireSafety',
    AuditMonthlyFireSafety = 'AuditMonthlyFireSafety',
    AuditBiAnnualFireSafety = 'AuditBiAnnualFireSafety',
    AuditAnnualFireSafety = 'AuditAnnualFireSafety',
    AuditFiveYearlyFireSafety = 'AuditFiveYearlyFireSafety'
}

/**
 * Primary container / categorisation for assessments
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * Unique ID for the category, assigned by service. ID of 0 means not yet inserted.
     * @type {number}
     * @memberof Category
     */
    'id': number;
    /**
     * Unique name for the category
     * @type {string}
     * @memberof Category
     */
    'name': string;
}
/**
 * Overall stats for the whole module
 * @export
 * @interface DashboardStats
 */
export interface DashboardStats {
    /**
     * Number of new audits for the calendar month
     * @type {number}
     * @memberof DashboardStats
     */
    'totalNewAudits': number;
    /**
     * Number of fire safety audits completed in the date range requested
     * @type {number}
     * @memberof DashboardStats
     */
    'totalAmountFireSafety': number;
    /**
     * Number of premises audits completed in the date range requested
     * @type {number}
     * @memberof DashboardStats
     */
    'totalAmountPremises': number;
    /**
     * Map of category name to number of audits created in the calendar month for that category
     * @type {{ [key: string]: number; }}
     * @memberof DashboardStats
     */
    'categoryNewAudits': { [key: string]: number; };
    /**
     * Map of venue name to number of audits created in the calendar month for that venue
     * @type {{ [key: string]: number; }}
     * @memberof DashboardStats
     */
    'venueNewAudits': { [key: string]: number; };
}
/**
 * An error has occurred, and this object contains the detailed information
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message': string;
}
/**
 * Historic version of an audit
 * @export
 * @interface HistoricAudit
 */
export interface HistoricAudit {
    /**
     * Unique ID for the audit
     * @type {number}
     * @memberof HistoricAudit
     */
    'id': number;
    /**
     * ID of the category this audit is within
     * @type {number}
     * @memberof HistoricAudit
     */
    'categoryId': number;
    /**
     * ID of the venue this audit is within
     * @type {number}
     * @memberof HistoricAudit
     */
    'venueId': number;
    /**
     * Date of the audit
     * @type {number}
     * @memberof HistoricAudit
     */
    'dateCreated': number;
    /**
     * 
     * @type {AuditType}
     * @memberof HistoricAudit
     */
    'type': AuditType;
    /**
     * 0-100 score for compliance
     * @type {number}
     * @memberof HistoricAudit
     */
    'complianceScore'?: number;
    /**
     * Username of the author
     * @type {string}
     * @memberof HistoricAudit
     */
    'authorId': string;
    /**
     * Name of the author
     * @type {string}
     * @memberof HistoricAudit
     */
    'authorName': string;
    /**
     * Raw JSON for the audit itself, stored as-is
     * @type {string}
     * @memberof HistoricAudit
     */
    'payload': string;
    /**
     * ID of the original / live audit
     * @type {number}
     * @memberof HistoricAudit
     */
    'originalId': number;
}
/**
 * 
 * @export
 * @interface HistoricAuditAllOf
 */
export interface HistoricAuditAllOf {
    /**
     * ID of the original / live audit
     * @type {number}
     * @memberof HistoricAuditAllOf
     */
    'originalId': number;
}
/**
 * Head for an earlier audit
 * @export
 * @interface HistoricAuditListEntry
 */
export interface HistoricAuditListEntry {
    /**
     * ID of the primary audit
     * @type {number}
     * @memberof HistoricAuditListEntry
     */
    'auditId': number;
    /**
     * ID of the specific historic record
     * @type {number}
     * @memberof HistoricAuditListEntry
     */
    'historicId': number;
    /**
     * 
     * @type {number}
     * @memberof HistoricAuditListEntry
     */
    'date': number;
    /**
     * Username of the author
     * @type {string}
     * @memberof HistoricAuditListEntry
     */
    'authorId': string;
    /**
     * Name of the author
     * @type {string}
     * @memberof HistoricAuditListEntry
     */
    'authorName': string;
}
/**
 * Request a list of historic audits
 * @export
 * @interface HistoricAuditListRequest
 */
export interface HistoricAuditListRequest {
    /**
     * Page to get, starting at 0
     * @type {number}
     * @memberof HistoricAuditListRequest
     */
    'pageNum'?: number;
    /**
     * Maximum number of entries to fetch per page
     * @type {number}
     * @memberof HistoricAuditListRequest
     */
    'numPerPage'?: number;
    /**
     * Original audit ID
     * @type {number}
     * @memberof HistoricAuditListRequest
     */
    'auditId': number;
    /**
     * Minimum creation date, inclusive
     * @type {number}
     * @memberof HistoricAuditListRequest
     */
    'minDate'?: number;
    /**
     * Maximum creation date, exclusive
     * @type {number}
     * @memberof HistoricAuditListRequest
     */
    'maxDate'?: number;
}
/**
 * List of historic versions for a single audit
 * @export
 * @interface HistoricAuditListResponse
 */
export interface HistoricAuditListResponse {
    /**
     * Page to get, starting at 0
     * @type {number}
     * @memberof HistoricAuditListResponse
     */
    'pageNum': number;
    /**
     * Maximum number of entries to fetch per page
     * @type {number}
     * @memberof HistoricAuditListResponse
     */
    'numPerPage': number;
    /**
     * Original audit ID
     * @type {number}
     * @memberof HistoricAuditListResponse
     */
    'auditId': number;
    /**
     * Minimum creation date, inclusive
     * @type {number}
     * @memberof HistoricAuditListResponse
     */
    'minDate'?: number;
    /**
     * Maximum creation date, exclusive
     * @type {number}
     * @memberof HistoricAuditListResponse
     */
    'maxDate'?: number;
    /**
     * Total number of entries that meet the specified criteria
     * @type {number}
     * @memberof HistoricAuditListResponse
     */
    'totalEntries': number;
    /**
     * Historic versions for this audit, can be an empty list if there are no historic versions
     * @type {Array<HistoricAuditListEntry>}
     * @memberof HistoricAuditListResponse
     */
    'results': Array<HistoricAuditListEntry>;
}
/**
 * Stats dashboard data for within risk management
 * @export
 * @interface RiskManagementStats
 */
export interface RiskManagementStats {
    /**
     * Number of new audits for the calendar month
     * @type {number}
     * @memberof RiskManagementStats
     */
    'totalNewAudits': number;
    /**
     * Number of fire safety audits completed in the date range requested
     * @type {number}
     * @memberof RiskManagementStats
     */
    'totalAmountFireSafety': number;
    /**
     * Number of premises audits completed in the date range requested
     * @type {number}
     * @memberof RiskManagementStats
     */
    'totalAmountPremises': number;
    /**
     * Map of category name to number of audits created in the calendar month for that category
     * @type {{ [key: string]: number; }}
     * @memberof RiskManagementStats
     */
    'categoryNewAudits': { [key: string]: number; };
    /**
     * Map of venue name to number of audits created in the calendar month for that venue
     * @type {{ [key: string]: number; }}
     * @memberof RiskManagementStats
     */
    'venueNewAudits': { [key: string]: number; };
    /**
     * Overall compliance percentage for all premises audits (all sub-types)
     * @type {number}
     * @memberof RiskManagementStats
     */
    'premisesCompliance': number;
    /**
     * Overall compliance percentage for fire safety audits
     * @type {number}
     * @memberof RiskManagementStats
     */
    'fireSafetyCompliance': number;
}
/**
 * 
 * @export
 * @interface RiskManagementStatsAllOf
 */
export interface RiskManagementStatsAllOf {
    /**
     * Overall compliance percentage for all premises audits (all sub-types)
     * @type {number}
     * @memberof RiskManagementStatsAllOf
     */
    'premisesCompliance': number;
    /**
     * Overall compliance percentage for fire safety audits
     * @type {number}
     * @memberof RiskManagementStatsAllOf
     */
    'fireSafetyCompliance': number;
}
/**
 * Parameters for a stats request
 * @export
 * @interface StatsRequest
 */
export interface StatsRequest {
    /**
     * Year the stats should be restricted to
     * @type {number}
     * @memberof StatsRequest
     */
    'year': number;
    /**
     * Month the stats should be restricted to
     * @type {number}
     * @memberof StatsRequest
     */
    'month': number;
}
/**
 * A unique location
 * @export
 * @interface Venue
 */
export interface Venue {
    /**
     * Unique ID for the venue, assigned by service. ID of 0 means not yet inserted.
     * @type {number}
     * @memberof Venue
     */
    'id': number;
    /**
     * Unique name for the venue
     * @type {string}
     * @memberof Venue
     */
    'name': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a specific audit
         * @param {number} auditid Unique ID of the audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudit: async (auditid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditid' is not null or undefined
            assertParamExists('getAudit', 'auditid', auditid)
            const localVarPath = `/audit/byid/{auditid}`
                .replace(`{${"auditid"}}`, encodeURIComponent(String(auditid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of audits that meet the specified criteria
         * @param {AuditListRequest} [auditListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditList: async (auditListRequest?: AuditListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audit/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a category with the specified ID
         * @param {number} id Unique ID of the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCategory', 'id', id)
            const localVarPath = `/category/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific historic version of an audit
         * @param {number} auditId Unique ID of the original audit
         * @param {number} historicId Unique ID of the historic audit version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricAudit: async (auditId: number, historicId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('getHistoricAudit', 'auditId', auditId)
            // verify required parameter 'historicId' is not null or undefined
            assertParamExists('getHistoricAudit', 'historicId', historicId)
            const localVarPath = `/audit/byid/{auditId}/historic/byid/{historicId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)))
                .replace(`{${"historicId"}}`, encodeURIComponent(String(historicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get historic listing for a single audit
         * @param {HistoricAuditListRequest} [historicAuditListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricAuditList: async (historicAuditListRequest?: HistoricAuditListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audit/historic/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(historicAuditListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get stats for dashboard
         * @param {StatsRequest} [statsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatsDashboard: async (statsRequest?: StatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get stats for risk management dashboard
         * @param {StatsRequest} [statsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatsRiskManagement: async (statsRequest?: StatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats/riskmanagement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a venue with the specified ID
         * @param {number} id Unique ID of the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenue: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVenue', 'id', id)
            const localVarPath = `/venue/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all the categories in the system, in alphabetical order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/category/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all the venues in the system, in alphabetical order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVenues: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/venue/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save an audit. If the ID is greater than 1, the audit will be saved as a new version and the existing audit archived to the historic record.
         * @param {Audit} [audit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAudit: async (audit?: Audit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(audit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a category. ID of 0 will insert, greater than 0 will update in-place
         * @param {Category} [category] Category to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCategory: async (category?: Category, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(category, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a venue. ID of 0 will insert, greater than 0 will update in-place
         * @param {Venue} [venue] Venue to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVenue: async (venue?: Venue, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/venue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(venue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a specific audit
         * @param {number} auditid Unique ID of the audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAudit(auditid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Audit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAudit(auditid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of audits that meet the specified criteria
         * @param {AuditListRequest} [auditListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditList(auditListRequest?: AuditListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditList(auditListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a category with the specified ID
         * @param {number} id Unique ID of the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategory(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific historic version of an audit
         * @param {number} auditId Unique ID of the original audit
         * @param {number} historicId Unique ID of the historic audit version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoricAudit(auditId: number, historicId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricAudit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoricAudit(auditId, historicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get historic listing for a single audit
         * @param {HistoricAuditListRequest} [historicAuditListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoricAuditList(historicAuditListRequest?: HistoricAuditListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricAuditListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoricAuditList(historicAuditListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get stats for dashboard
         * @param {StatsRequest} [statsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatsDashboard(statsRequest?: StatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatsDashboard(statsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get stats for risk management dashboard
         * @param {StatsRequest} [statsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatsRiskManagement(statsRequest?: StatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskManagementStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatsRiskManagement(statsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a venue with the specified ID
         * @param {number} id Unique ID of the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVenue(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Venue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVenue(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all the categories in the system, in alphabetical order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all the venues in the system, in alphabetical order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVenues(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Venue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVenues(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save an audit. If the ID is greater than 1, the audit will be saved as a new version and the existing audit archived to the historic record.
         * @param {Audit} [audit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAudit(audit?: Audit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Audit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAudit(audit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a category. ID of 0 will insert, greater than 0 will update in-place
         * @param {Category} [category] Category to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCategory(category?: Category, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCategory(category, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a venue. ID of 0 will insert, greater than 0 will update in-place
         * @param {Venue} [venue] Venue to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveVenue(venue?: Venue, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Venue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveVenue(venue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a specific audit
         * @param {number} auditid Unique ID of the audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudit(auditid: number, options?: any): AxiosPromise<Audit> {
            return localVarFp.getAudit(auditid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of audits that meet the specified criteria
         * @param {AuditListRequest} [auditListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditList(auditListRequest?: AuditListRequest, options?: any): AxiosPromise<AuditListResponse> {
            return localVarFp.getAuditList(auditListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a category with the specified ID
         * @param {number} id Unique ID of the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory(id: number, options?: any): AxiosPromise<Category> {
            return localVarFp.getCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific historic version of an audit
         * @param {number} auditId Unique ID of the original audit
         * @param {number} historicId Unique ID of the historic audit version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricAudit(auditId: number, historicId: number, options?: any): AxiosPromise<HistoricAudit> {
            return localVarFp.getHistoricAudit(auditId, historicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get historic listing for a single audit
         * @param {HistoricAuditListRequest} [historicAuditListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricAuditList(historicAuditListRequest?: HistoricAuditListRequest, options?: any): AxiosPromise<HistoricAuditListResponse> {
            return localVarFp.getHistoricAuditList(historicAuditListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get stats for dashboard
         * @param {StatsRequest} [statsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatsDashboard(statsRequest?: StatsRequest, options?: any): AxiosPromise<DashboardStats> {
            return localVarFp.getStatsDashboard(statsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get stats for risk management dashboard
         * @param {StatsRequest} [statsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatsRiskManagement(statsRequest?: StatsRequest, options?: any): AxiosPromise<RiskManagementStats> {
            return localVarFp.getStatsRiskManagement(statsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a venue with the specified ID
         * @param {number} id Unique ID of the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenue(id: number, options?: any): AxiosPromise<Venue> {
            return localVarFp.getVenue(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all the categories in the system, in alphabetical order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategories(options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.listCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all the venues in the system, in alphabetical order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVenues(options?: any): AxiosPromise<Array<Venue>> {
            return localVarFp.listVenues(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save an audit. If the ID is greater than 1, the audit will be saved as a new version and the existing audit archived to the historic record.
         * @param {Audit} [audit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAudit(audit?: Audit, options?: any): AxiosPromise<Audit> {
            return localVarFp.saveAudit(audit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a category. ID of 0 will insert, greater than 0 will update in-place
         * @param {Category} [category] Category to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCategory(category?: Category, options?: any): AxiosPromise<Category> {
            return localVarFp.saveCategory(category, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a venue. ID of 0 will insert, greater than 0 will update in-place
         * @param {Venue} [venue] Venue to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVenue(venue?: Venue, options?: any): AxiosPromise<Venue> {
            return localVarFp.saveVenue(venue, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Get a specific audit
     * @param {number} auditid Unique ID of the audit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAudit(auditid: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAudit(auditid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of audits that meet the specified criteria
     * @param {AuditListRequest} [auditListRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuditList(auditListRequest?: AuditListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAuditList(auditListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a category with the specified ID
     * @param {number} id Unique ID of the category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCategory(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific historic version of an audit
     * @param {number} auditId Unique ID of the original audit
     * @param {number} historicId Unique ID of the historic audit version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHistoricAudit(auditId: number, historicId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHistoricAudit(auditId, historicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get historic listing for a single audit
     * @param {HistoricAuditListRequest} [historicAuditListRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHistoricAuditList(historicAuditListRequest?: HistoricAuditListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHistoricAuditList(historicAuditListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get stats for dashboard
     * @param {StatsRequest} [statsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStatsDashboard(statsRequest?: StatsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStatsDashboard(statsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get stats for risk management dashboard
     * @param {StatsRequest} [statsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStatsRiskManagement(statsRequest?: StatsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStatsRiskManagement(statsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a venue with the specified ID
     * @param {number} id Unique ID of the venue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVenue(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVenue(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all the categories in the system, in alphabetical order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCategories(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all the venues in the system, in alphabetical order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listVenues(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listVenues(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save an audit. If the ID is greater than 1, the audit will be saved as a new version and the existing audit archived to the historic record.
     * @param {Audit} [audit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveAudit(audit?: Audit, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveAudit(audit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a category. ID of 0 will insert, greater than 0 will update in-place
     * @param {Category} [category] Category to save
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveCategory(category?: Category, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveCategory(category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a venue. ID of 0 will insert, greater than 0 will update in-place
     * @param {Venue} [venue] Venue to save
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveVenue(venue?: Venue, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveVenue(venue, options).then((request) => request(this.axios, this.basePath));
    }
}


