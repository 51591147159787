import React from "react";
import PrintableTable from "../PrintableTable";
import PrintableTableRow from "../PrintableTableRow";
import {MatrixRating} from "../../../../store/audit/helpers/RiskAssessment/riskAssessmentMatrixHelpers";
import PrintableTD from "../PrintableTD";
import PrintableTableBody from "../PrintableTableBody";

const MatrixRatingTableRowItem = (props: MatrixRating) => {
    const getMatrixRatingTable = () => {
        const {severity, likelihood} = props.severityLikelihoodMatrix;
        return severity * likelihood;
    };

    const getColourForTotal = () => {
        const total = getMatrixRatingTable();

        if (total < 6) {
            return "#41bd6a";
        }
        if (total < 11) {
            return "#e0cc65";
        }
        if (total < 15) {
            return "#d2823e";
        }
        if (total < 21) {
            return "#f57571";
        }
        return "#e2403b";
    };
    return (
        <React.Fragment>
            <PrintableTable marginTop={"0"}>
                <PrintableTableBody>
                    <PrintableTableRow rowType={"bodyRow"}>
                        <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                            <span>{props.severityLikelihoodMatrix.severity}</span>
                        </PrintableTD>
                        <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                            <span>{props.severityLikelihoodMatrix.severity}</span>
                        </PrintableTD>
                        <PrintableTD
                            backgroundColor={getColourForTotal()}
                            alignment={"center"}
                            padding="1rem"
                            minWidth={"16px"}
                            maxWidth={"350px"}
                        >
                            <span>{getMatrixRatingTable()}</span>
                        </PrintableTD>
                    </PrintableTableRow>
                </PrintableTableBody>
            </PrintableTable>
        </React.Fragment>
    );
};

export default MatrixRatingTableRowItem;
