import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {nullifyCategoryListStore} from "../../../store/categoryList/actions/CategoryListActions";
import {nullifyVenueListStore} from "../../../store/venueList/actions/VenueListActions";
import AuditListTable from "./Components/AuditListTable";
import {WithServiceState} from "store-fetch-wrappers";
import {RootStore} from "../../../store/Store";
import {fetchPagedAuditListWithFilters} from "../../../store/auditListPaged/actions/AuditListPagedActions";

const ServiceWrapper = WithServiceState(AuditListTable);

const AuditListPaged = () => {
    const dispatch = useDispatch();
    const auditListStore = useSelector((state: RootStore) => state.auditListPaged);

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.venueList.name,
                path: routeNames.venueList.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));
        dispatch(fetchPagedAuditListWithFilters());

        return () => {
            dispatch(nullifyCategoryListStore());
            dispatch(nullifyVenueListStore());
        };
    }, []);

    return (
        <React.Fragment>
            <div className="page-container pt-4">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    {...auditListStore}
                />
            </div>
        </React.Fragment>
    );
};

export default AuditListPaged;
