import React, {useEffect, useState} from "react";
import {Venue} from "../../api/rm";
import {Loading} from "store-fetch-wrappers";
import Select, {SingleValue} from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../store/Store";
import {
    DDProps,
    DropdownProps,
    getSelectedDropdownOptionByValue,
    sortGenericNameIdToDropdownProps
} from "./Helpers/dropdownUtils";
import {nullifyVenueListStore} from "../../store/venueList/actions/VenueListActions";

const VenueDropdown = (props: DropdownProps<Venue>) => {
    const venueListStore = useSelector((state: RootStore) => state.venueList);
    const [venueOptions, setVenueOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps | undefined>(undefined);
    const dispatch = useDispatch();

    /** Get the venue list upon mounting */
    useEffect(() => {
        if (!venueListStore.data) return;
        processVenuesIncoming(venueListStore.data);

        return function () {
            dispatch(nullifyVenueListStore());
        };
    }, []);

    const processVenuesIncoming = (venues: Venue[]) => {
        const options = sortGenericNameIdToDropdownProps(venues);
        setVenueOptions(options);

        if (props.item.id > 0) {
            const option = getSelectedDropdownOptionByValue(props.item.id, options);
            setSelectedOption(option);
            props.changeOption(option ? {id: +option.value, name: option.label} : undefined);
            return;
        }

        if (options.length === 0 || props.clearable) {
            setSelectedOption(undefined);
            props.changeOption(undefined);
            return;
        }

        const option = getSelectedDropdownOptionByValue(props.item.id, options);
        setSelectedOption(option);
        props.changeOption(option ? {id: +option.value, name: option.label} : undefined);
    };

    /** Fired when a new option is selected */
    const handleVenueChange = (newValue?: SingleValue<DDProps>) => {
        if (!newValue) {
            props.changeOption(undefined);
            setSelectedOption(undefined);
            return;
        }
        const option = getSelectedDropdownOptionByValue(newValue.value, venueOptions);
        setSelectedOption(option);
        props.changeOption(option ? {id: +option.value, name: option.label} : undefined);
    };

    return (
        <React.Fragment>
            {venueListStore.loading ? (
                <Loading type={"three-ring"} showLoadingText={false} />
            ) : (
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={venueOptions}
                    onChange={handleVenueChange}
                    isSearchable={props.searchable}
                    value={selectedOption}
                    noOptionsMessage={() => "No Venues Found"}
                    placeholder="Select Venue"
                    isDisabled={props.disabled}
                    isClearable={props.clearable}
                    menuPortalTarget={document.body}
                />
            )}
        </React.Fragment>
    );
};

export default VenueDropdown;
