import {
    Answer,
    newQuestionWithAnswer,
    newSectionDetailItem,
    QuestionWithAnswer,
    SectionDetails
} from "../auditHelpers";

export interface WeeklyFireSafetyAudit {
    callPointQuestion: string;
    callPoint1: QuestionWithAnswer;
    callPoint2: QuestionWithAnswer;
    callPoint3: QuestionWithAnswer;
    callPoint4: QuestionWithAnswer;
    doorReleasesWorking: QuestionWithAnswer;
    fireExtinguishersChecked: QuestionWithAnswer;
    ceilingMountedDetectorsUnobstructed: QuestionWithAnswer;
    fireDoorsUnobstructedAndNotProppedOpen: QuestionWithAnswer;
    fireDoorsInWorkingOrder: QuestionWithAnswer;
    anyVisibleDamageToFireDoors: QuestionWithAnswer;
    fireExitsClearlyMarked: QuestionWithAnswer;
    fireRoutesUnobstructed: QuestionWithAnswer;
    fireRoutesClearlyMarked: QuestionWithAnswer;
    fireInstructionsPositionedInObviousPlace: QuestionWithAnswer;
    suitableAndSufficientFireSignageDisplayed: QuestionWithAnswer;
    kitchenFireBlanket: QuestionWithAnswer;
    exteriorMedicalGassesSignageDisplayed: QuestionWithAnswer;
    fireActionPlanDisplayedThroughoutBuilding: QuestionWithAnswer;
    fireAssemblyPointClearlySignposted: QuestionWithAnswer;
    weeklyDetails: SectionDetails;
}

export const fireSafetyWeeklyQuestionOrder: string[] = [
    "callPointQuestion",
    "callPoint1",
    "callPoint2",
    "callPoint3",
    "callPoint4",
    "doorReleasesWorking",
    "fireExtinguishersChecked",
    "ceilingMountedDetectorsUnobstructed",
    "fireDoorsUnobstructedAndNotProppedOpen",
    "fireDoorsInWorkingOrder",
    "anyVisibleDamageToFireDoors",
    "fireExitsClearlyMarked",
    "fireRoutesUnobstructed",
    "fireRoutesClearlyMarked",
    "fireInstructionsPositionedInObviousPlace",
    "suitableAndSufficientFireSignageDisplayed",
    "kitchenFireBlanket",
    "exteriorMedicalGassesSignageDisplayed",
    "fireActionPlanDisplayedThroughoutBuilding",
    "fireAssemblyPointClearlySignposted",
    "weeklyDetails"
];

export function generateBlankWeeklyFireSafetyAudit(): WeeklyFireSafetyAudit {
    return {
        callPointQuestion: "Fire detection system tested?",
        callPoint1: newQuestionWithAnswer("Call point 1?", Answer.No, 0, true),
        callPoint2: newQuestionWithAnswer("Call point 2?", Answer.No, 0, true),
        callPoint3: newQuestionWithAnswer("Call point 3?", Answer.No, 0, true),
        callPoint4: newQuestionWithAnswer("Call point 4?", Answer.No, 0, true),
        doorReleasesWorking: newQuestionWithAnswer("Door releases working?", Answer.No),
        fireExtinguishersChecked: newQuestionWithAnswer("Fire extinguishers checked?", Answer.No),
        ceilingMountedDetectorsUnobstructed: newQuestionWithAnswer(
            "Ceiling mounted detectors unobstructed?",
            Answer.No
        ),
        fireDoorsUnobstructedAndNotProppedOpen: newQuestionWithAnswer(
            "Fire doors unobstructed and not propped open?",
            Answer.No
        ),
        fireDoorsInWorkingOrder: newQuestionWithAnswer("Fire doors in working order?", Answer.No),
        anyVisibleDamageToFireDoors: newQuestionWithAnswer(
            "Any visible damage to fire doors?",
            Answer.No
        ),
        fireExitsClearlyMarked: newQuestionWithAnswer("Fire exits clearly marked?", Answer.No),
        fireRoutesUnobstructed: newQuestionWithAnswer("Fire routes unobstructed?", Answer.No),
        fireRoutesClearlyMarked: newQuestionWithAnswer("Fire routes clearly marked?", Answer.No),
        fireInstructionsPositionedInObviousPlace: newQuestionWithAnswer(
            "Fire instructions positioned in obvious places?",
            Answer.No
        ),
        suitableAndSufficientFireSignageDisplayed: newQuestionWithAnswer(
            "Suitable and sufficient fire signage displayed?",
            Answer.No
        ),
        kitchenFireBlanket: newQuestionWithAnswer("Fire blanket (kitchen)?", Answer.No),
        exteriorMedicalGassesSignageDisplayed: newQuestionWithAnswer(
            "Caution ‘medical gasses’ signage (building exterior)?",
            Answer.No
        ),
        fireActionPlanDisplayedThroughoutBuilding: newQuestionWithAnswer(
            "‘Fire Action Plan’ on display throughout the building?",
            Answer.No
        ),
        fireAssemblyPointClearlySignposted: newQuestionWithAnswer(
            "Fire assembly point clearly signposted?",
            Answer.No
        ),
        weeklyDetails: newSectionDetailItem("Weekly check comments/details")
    };
}
