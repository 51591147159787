import React from "react";
import {
    fireSafetyWeeklyQuestionOrder,
    WeeklyFireSafetyAudit
} from "../../../../../../../store/audit/helpers/FireSafety/weeklyFireSafetyAudit";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {AuditType} from "../../../../../../../api/rm";
import QuestionBlock from "../../General/QuestionBlock";

const WeeklyFireAudit = (props: WeeklyFireSafetyAudit) => {
    return (
        <React.Fragment>
            <QuestionBlock
                headerName={getUiFriendlyText(AuditType.AuditWeeklyFireSafety)}
                showYesNoAnswerOnly={true}
                nestingList={["weeklyFireSafetyAudit"]}
                checklist={props}
                questionOrder={fireSafetyWeeklyQuestionOrder}
            />
        </React.Fragment>
    );
};

export default WeeklyFireAudit;
