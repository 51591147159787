import React from "react";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {AuditType} from "../../../../../../../api/rm";
import QuestionBlock from "../../General/QuestionBlock";
import {
    BiAnnualFireSafetyAudit,
    fireSafetyBiAnnualQuestionOrder
} from "../../../../../../../store/audit/helpers/FireSafety/biAnnualFireSafetyAudit";

const BiAnnualFireAudit = (props: BiAnnualFireSafetyAudit) => {
    return (
        <React.Fragment>
            <QuestionBlock
                headerName={getUiFriendlyText(AuditType.AuditBiAnnualFireSafety)}
                showYesNoAnswerOnly={true}
                nestingList={["biAnnualFireSafetyAudit"]}
                checklist={props}
                questionOrder={fireSafetyBiAnnualQuestionOrder}
            />
        </React.Fragment>
    );
};

export default BiAnnualFireAudit;
