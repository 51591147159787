import React from "react";
import ReportHeader from "../../../../../../../Printable/Components/ReportHeader";
import {
    RiskAssessmentMatrix,
    RiskAssessmentMatrixRow
} from "../../../../../../../../store/audit/helpers/RiskAssessment/riskAssessmentMatrixHelpers";
import PrintableTable from "../../../../../../../Printable/Components/PrintableTable";
import PrintableTableBody from "../../../../../../../Printable/Components/PrintableTableBody";
import PrintableRiskAssessmentTableHeader from "../../../../../../../Printable/Components/RiskAssessment/PrintableRiskAssessmentTableHeader";
import PrintableRiskAssessmentTableRow from "../../../../../../../Printable/Components/RiskAssessment/PrintableRiskAssessmentTableRow";
import ReportFooter from "../../../../../../../Printable/Components/RiskAssessment/ReportFooter";

const PrintableRiskAssessmentMatrix = (props: PrintableRiskAssessmentMatrixProps) => {
    return (
        <React.Fragment>
            <ReportHeader
                reportHeaderText={`Medicare EMS Group UK Limited Risk Register (${props.categoryName} - ${props.venueName})`}
            />
            <PrintableTable marginTop={"15px"} width={"1920px"}>
                <PrintableRiskAssessmentTableHeader />
                <PrintableTableBody>
                    {props.matrix.rows.map((item: RiskAssessmentMatrixRow) => {
                        return <PrintableRiskAssessmentTableRow {...item} key={item.id} />;
                    })}
                </PrintableTableBody>
            </PrintableTable>
            <ReportFooter />
        </React.Fragment>
    );
};

export default PrintableRiskAssessmentMatrix;

interface PrintableRiskAssessmentMatrixProps {
    categoryName?: string;
    venueName?: string;
    matrix: RiskAssessmentMatrix;
}
