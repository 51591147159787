import React from "react";
import PrintableTable from "../PrintableTable";
import PrintableTableHead from "../PrintableTableHead";
import PrintableTableRow from "../PrintableTableRow";
import PrintableTH from "../PrintableTH";

const MatrixRatingTableHeader = (props: MatrixRatingTableHeaderProps) => {
    return (
        <React.Fragment>
            {props.headerName}
            <PrintableTable marginTop={"0"}>
                <PrintableTableHead>
                    <PrintableTableRow rowType={"headerRow"}>
                        <PrintableTH alignment={"left"} padding="0" maxWidth={"100px"}>
                            S
                        </PrintableTH>
                        <PrintableTH alignment={"left"} padding="0" maxWidth={"100px"}>
                            L
                        </PrintableTH>
                        <PrintableTH alignment={"left"} padding="0" maxWidth={"100px"}>
                            T
                        </PrintableTH>
                    </PrintableTableRow>
                </PrintableTableHead>
            </PrintableTable>
        </React.Fragment>
    );
};

export default MatrixRatingTableHeader;

interface MatrixRatingTableHeaderProps {
    headerName: string;
}
