import React, {useEffect} from "react";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {useDispatch, useSelector} from "react-redux";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {RootStore} from "../../../store/Store";
import HistoricAuditListTable from "./Components/HistoricAuditListTable";
import {WithServiceState} from "store-fetch-wrappers";
const ServiceWrapper = WithServiceState(HistoricAuditListTable);

const AuditHistoricListPaged = () => {
    const dispatch = useDispatch();
    const {fullPath} = usePageUrl();
    const auditListHistoricPagedStore = useSelector(
        (state: RootStore) => state.auditListHistoricPaged
    );

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.venueList.name,
                path: routeNames.venueList.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            },
            {
                name: routeNames.auditHistoricList.name,
                path: fullPath
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));
    }, []);

    return (
        <React.Fragment>
            <div className="page-container">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    {...auditListHistoricPagedStore}
                />
            </div>
        </React.Fragment>
    );
};

export default AuditHistoricListPaged;
