import React from "react";
import PrintableTable from "./PrintableTable";
import PrintableTableHead from "./PrintableTableHead";
import PrintableTableRow from "./PrintableTableRow";
import PrintableTH from "./PrintableTH";

const ReportHeader = (props: ReportHeaderProps) => {
    return (
        <React.Fragment>
            <PrintableTable width={"1920px"}>
                <PrintableTableHead>
                    <PrintableTableRow rowType={"headerRow"}>
                        <PrintableTH alignment={"right"} padding={"2rem"} maxWidth={"1000px"}>
                            <h4 style={{marginBottom: `0`}}>{props.reportHeaderText}</h4>
                        </PrintableTH>
                    </PrintableTableRow>
                </PrintableTableHead>
            </PrintableTable>
        </React.Fragment>
    );
};

export default ReportHeader;

interface ReportHeaderProps {
    reportHeaderText: string;
}
