import {DefaultApi as RmApi} from "../../api/rm";
import {ApiPluginOptions} from "./BaseApi";

export default class RiskManagementApiModel {
    private static instance: RiskManagementApiModel;
    private static rmApi: RmApi;
    private static rmApiOptions: ApiPluginOptions | undefined;

    public static getRmApi(): RmApi {
        if (!RiskManagementApiModel.rmApi) {
            RiskManagementApiModel.rmApi = new RmApi({
                basePath: process.env.REACT_APP_RM_API,
                isJsonMime(mime: string): boolean {
                    return true;
                }
            });
        }

        return RiskManagementApiModel.rmApi;
    }
}
