import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {getAllCategories} from "../../../store/categoryList/actions/CategoryListActions";
import {RootStore} from "../../../store/Store";
import CategoryListTable from "./Components/CategoryListTable";
import {WithServiceState} from "store-fetch-wrappers";

const ServiceWrapper = WithServiceState(CategoryListTable);

const CategoryList = () => {
    const dispatch = useDispatch();
    const categoryListStore = useSelector((state: RootStore) => state.categoryList);
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.venueList.name,
                path: routeNames.venueList.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));

        dispatch(getAllCategories());
    }, []);

    return (
        <React.Fragment>
            <div className="page-container pt-4">
                <ServiceWrapper
                    {...categoryListStore}
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                />
            </div>
        </React.Fragment>
    );
};

export default CategoryList;
