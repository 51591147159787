import React from "react";
import {
    Answer,
    QuestionWithAnswerAndCommentsAndDate,
    YesNo
} from "../../store/audit/helpers/auditHelpers";
import GenericTypeDropdown from "../Dropdown/GenericTypeDropdown";
import {OptionsFromType} from "../Dropdown/Helpers/dropdownUtils";
import moment from "moment";
import DatePicker from "react-datepicker";
import DatePickerInputButton from "../Button/DatePickerInputButton";
import {useIsHistoricAudit} from "../Hooks/useIsHistoricAudit";
import {formatUnixToDDMMYYYY} from "../../utils/momentUtils";
import {getUiFriendlyText} from "../../utils/textUtils";
import FormRow from "./FormRow";
import {DebounceInput} from "react-debounce-input";

const QuestionWithAnswerAndCommentsAndDateRow = (
    props: QuestionWithAnswerAndCommentsAndDateWithCallback
) => {
    const isHistoric = useIsHistoricAudit();

    /** Gets the value of the Answer enum from a string  */
    const getAnswerFromString = (value: string): Answer => {
        return Answer[value as keyof typeof Answer];
    };

    const getStartDate = () => {
        return props.date ? moment.unix(props.date).toDate() : undefined;
    };

    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                <div className="col pl-0">
                    <FormRow
                        rowName={props.question}
                        columnDetailClassName={!isHistoric ? "pl-0 pr-0" : ""}
                    >
                        {!isHistoric ? (
                            <GenericTypeDropdown
                                enumOptions={props.showYesNoAnswerOnly ? YesNo : Answer}
                                splitByCapitalLetter={true}
                                getOptionsFrom={OptionsFromType.Key}
                                searchWithDecapitalisedFirstLetter={false}
                                clearable={false}
                                searchable={false}
                                changeOption={(item) => {
                                    const newAnswer = getAnswerFromString(item.toString());

                                    props.onChange({
                                        ...props,
                                        answer: newAnswer
                                    });
                                }}
                                id={props.answer}
                                disabled={isHistoric}
                            />
                        ) : (
                            <p className="body-font">{getUiFriendlyText(props.answer)}</p>
                        )}
                    </FormRow>
                </div>
                <div className="col pl-0 pr-0">
                    <FormRow
                        rowName={props.dateLabel || ""}
                        columnDetailClassName={!isHistoric ? "pl-0 pr-0" : ""}
                    >
                        {!isHistoric ? (
                            <DatePicker
                                selected={getStartDate()}
                                onChange={(date) => {
                                    const updatedDate = date
                                        ? moment(date).startOf("day").unix()
                                        : 0;

                                    props.onChange({
                                        ...props,
                                        date: updatedDate
                                    });
                                }}
                                dateFormat="dd/MM/yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                                placeholderText="Select start date"
                                isClearable
                                disabled={isHistoric}
                            />
                        ) : (
                            <p className="body-font">
                                {props.date > 0
                                    ? formatUnixToDDMMYYYY(props.date)
                                    : "Date not added."}
                            </p>
                        )}
                    </FormRow>
                </div>
            </div>

            <FormRow rowName={"Comments"} columnDetailClassName={!isHistoric ? "pl-0 pr-0" : ""}>
                {!isHistoric ? (
                    <DebounceInput
                        debounceTimeout={300}
                        element={"textarea"}
                        className="input-fields text-area-inputs"
                        value={props.comments}
                        onChange={(event) => {
                            const {value} = event.target;

                            props.onChange({
                                ...props,
                                comments: value
                            });
                        }}
                        placeholder="Enter details here..."
                    />
                ) : (
                    <p className="body-font">{props.comments}</p>
                )}
            </FormRow>
        </React.Fragment>
    );
};

export default QuestionWithAnswerAndCommentsAndDateRow;

/** Interface containing new answer in a callback*/
interface QuestionWithAnswerAndCommentsAndDateWithCallback
    extends QuestionWithAnswerAndCommentsAndDate {
    onChange: (updatedQuestion: QuestionWithAnswerAndCommentsAndDate) => void;
    question: string;
    rowClassName?: string;
    formRowClassName?: string;
    questionKey: string;
    showYesNoAnswerOnly: boolean;
}
