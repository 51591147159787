import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {Category} from "../../../api/rm";

export const CATEGORY_LIST_STORE = createStoreState("category_list");

interface CategoryListLoading extends StoreServiceData<Category[]> {
    type: typeof CATEGORY_LIST_STORE.LOADING;
}
interface CategoryListError extends StoreServiceData<Category[]> {
    type: typeof CATEGORY_LIST_STORE.ERROR;
}
interface CategoryListSuccess extends StoreServiceData<Category[]> {
    type: typeof CATEGORY_LIST_STORE.SUCCESS;
}

export type CategoryListDispatchTypes =
    | CategoryListLoading
    | CategoryListError
    | CategoryListSuccess;
