import React, {useState} from "react";
import moment, {Moment} from "moment";
import DatePickerInputButton from "../Button/DatePickerInputButton";
import DatePicker from "react-datepicker";

const PulseDatePicker = (props: PulseDatePickerProps) => {
    const [startDate, setStartDate] = useState<Moment | undefined>(props.startDate);

    const onDateChanged = (date: Date) => {
        setStartDate(moment(date));
        props.onDateChanged(moment(date));
    };
    return (
        <React.Fragment>
            <DatePicker
                selected={startDate?.toDate()}
                onChange={onDateChanged}
                showMonthYearPicker={props.showMonthYearPicker}
                dateFormat={props.dateFormat}
                portalId="root-portal"
                customInput={<DatePickerInputButton />}
                placeholderText={props.placeHolder ? props.placeHolder : "Select start date"}
                disabled={props.disabled}
                isClearable={props.isClearable}
                showYearPicker={props.showYearPicker}
                className={props.className}
            />
        </React.Fragment>
    );
};

export default PulseDatePicker;

interface PulseDatePickerProps {
    placeHolder?: string;
    dateFormat: string;
    showMonthYearPicker?: boolean;
    showYearPicker?: boolean;
    isClearable?: boolean;
    disabled?: boolean;
    startDate?: Moment;
    onDateChanged: (newDate: Moment) => void;
    className?: string;
}
