import React from "react";
import {
    fireSafety5YearlyQuestionOrder,
    fireSafetyAnnualQuestionOrder,
    FireSafetyAudit,
    fireSafetyBiAnnualQuestionOrder,
    fireSafetyMonthlyQuestionOrder,
    fireSafetyWeeklyQuestionOrder
} from "../../../../../../../store/audit/helpers/FireSafety/fireSafetyAuditHelpers";
import FormHeader from "../../../../../../Form/FormHeader";
import QuestionBlock from "../../General/QuestionBlock";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {AuditType} from "../../../../../../../api/rm";

const FireSafetyAuditForm = (props: FireSafetyAudit) => {
    return (
        <React.Fragment>
            <FormHeader headerName={getUiFriendlyText(AuditType.AuditFireSafety)} />
            <QuestionBlock
                headerName={"Weekly Checks"}
                showYesNoAnswerOnly={true}
                nestingList={["fireSafetyAudit", "weeklyChecks"]}
                checklist={props.weeklyChecks}
                questionOrder={fireSafetyWeeklyQuestionOrder}
            />
            <QuestionBlock
                headerName={"Monthly Checks"}
                showYesNoAnswerOnly={true}
                nestingList={["fireSafetyAudit", "monthlyChecks"]}
                checklist={props.monthlyChecks}
                questionOrder={fireSafetyMonthlyQuestionOrder}
            />
            <QuestionBlock
                headerName={"Bi-Annual Checks"}
                showYesNoAnswerOnly={true}
                nestingList={["fireSafetyAudit", "biAnnualChecks"]}
                checklist={props.biAnnualChecks}
                questionOrder={fireSafetyBiAnnualQuestionOrder}
            />
            <QuestionBlock
                headerName={"Annual"}
                showYesNoAnswerOnly={true}
                nestingList={["fireSafetyAudit", "annualChecks"]}
                checklist={props.annualChecks}
                questionOrder={fireSafetyAnnualQuestionOrder}
            />
            <QuestionBlock
                headerName={"5 Yearly Checks"}
                showYesNoAnswerOnly={true}
                nestingList={["fireSafetyAudit", "fiveYearlyChecks"]}
                checklist={props.fiveYearlyChecks}
                questionOrder={fireSafety5YearlyQuestionOrder}
            />
        </React.Fragment>
    );
};

export default FireSafetyAuditForm;
