import React, {useEffect, useState} from "react";
import PulseDatePicker from "../../../DatePicker/PulseDatePicker";
import moment, {Moment} from "moment";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import RiskManagementStatsWrapper from "./RiskManagementStatsWrapper";
import {WithServiceState} from "store-fetch-wrappers";
import {getStatsForRiskManagement} from "../../../../store/stats/actions/StatsActions";

const ServiceWrapper = WithServiceState(RiskManagementStatsWrapper);

const RiskManagementStats = () => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState<Moment>(moment().startOf("day"));
    const statsStore = useSelector((state: RootStore) => state.stats);

    const onDateChanged = (date: Moment) => {
        setStartDate(date);
    };

    useEffect(() => {
        const month = startDate.month() + 1;
        const year = startDate.year();

        dispatch(getStatsForRiskManagement({month, year}));
    }, [startDate]);
    return (
        <React.Fragment>
            <div className="page-container pt-4">
                <div className="row ml-0 mr-0">
                    <div className="col pr-0 d-flex justify-content-end">
                        <div className="max-width-250px">
                            <PulseDatePicker
                                startDate={startDate}
                                dateFormat={"MMM yyyy"}
                                onDateChanged={onDateChanged}
                                showMonthYearPicker
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ServiceWrapper
                showLoadingText={true}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
                placeholderBlockCount={1}
                {...statsStore}
            />
        </React.Fragment>
    );
};

export default RiskManagementStats;
