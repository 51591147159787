import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {RiskManagementStats} from "../../../api/rm";

export const STATS_STORE = createStoreState("stats_store");

interface StatsStoreLoading extends StoreServiceData<RiskManagementStats> {
    type: typeof STATS_STORE.LOADING;
}
interface StatsStoreError extends StoreServiceData<RiskManagementStats> {
    type: typeof STATS_STORE.ERROR;
}
interface StatsStoreSuccess extends StoreServiceData<RiskManagementStats> {
    type: typeof STATS_STORE.SUCCESS;
}

export type StatsDispatchTypes = StatsStoreLoading | StatsStoreError | StatsStoreSuccess;
