import React, {useEffect, useState} from "react";
import {Category, Venue} from "../../api/rm";
import Select, {SingleValue} from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../store/Store";
import {
    DDProps,
    DropdownProps,
    getSelectedDropdownOptionByValue,
    sortGenericNameIdToDropdownProps
} from "./Helpers/dropdownUtils";

import {nullifyCategoryListStore} from "../../store/categoryList/actions/CategoryListActions";
import {getEmptyCategory} from "../../store/category/actions/CategoryActions";
import {Loading} from "store-fetch-wrappers";

const CategoryDropdown = (props: DropdownProps<Venue>) => {
    const categoryListStore = useSelector((state: RootStore) => state.categoryList);
    const [categoryOptions, setCategoryOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps | undefined>(undefined);
    const dispatch = useDispatch();

    /** Get the venue list upon mounting */
    useEffect(() => {
        if (!categoryListStore.data) return;
        processCategoriesIncoming(categoryListStore.data);

        return function () {
            dispatch(nullifyCategoryListStore());
        };
    }, []);

    const processCategoriesIncoming = (venues: Category[]) => {
        const options = sortGenericNameIdToDropdownProps(venues);
        setCategoryOptions(options);

        if (props.item.id > 0) {
            const option = getSelectedDropdownOptionByValue(props.item.id, options);
            setSelectedOption(option);
            props.changeOption(option ? {id: +option.value, name: option.label} : undefined);
            return;
        }

        if (options.length === 0 || props.clearable) {
            setSelectedOption(undefined);
            props.changeOption(getEmptyCategory());
            return;
        }

        const option = getSelectedDropdownOptionByValue(props.item.id, options);
        setSelectedOption(option);
        props.changeOption({id: +option.value, name: option.label});
    };

    /** Fired when a new option is selected */
    const handleCategoryChange = (newValue?: SingleValue<DDProps>) => {
        if (!newValue) {
            props.changeOption(undefined);
            setSelectedOption(undefined);
            return;
        }
        const option = getSelectedDropdownOptionByValue(newValue.value, categoryOptions);
        setSelectedOption(option);
        props.changeOption({id: +option.value, name: option.label});
    };

    return (
        <React.Fragment>
            {categoryListStore.loading ? (
                <Loading type={"three-ring"} showLoadingText={false} />
            ) : (
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={categoryOptions}
                    onChange={handleCategoryChange}
                    isSearchable={props.searchable}
                    value={selectedOption}
                    noOptionsMessage={() => "No Categories Found"}
                    placeholder="Select Category"
                    isDisabled={props.disabled}
                    isClearable={props.clearable}
                    menuPortalTarget={document.body}
                />
            )}
        </React.Fragment>
    );
};

export default CategoryDropdown;
