import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {RiskManagementStats} from "../../../api/rm";
import {showErrorToast} from "../../../utils/toastUtils";
import {STATS_STORE} from "../actions/StatsActionTypes";

/** Default state of the store. */
const defaultState = createDefaultStoreState<RiskManagementStats>(null);

/** Infer type of the reducer for code completion and quality */
const statsReducer = (
    state: StoreServiceData<RiskManagementStats> = defaultState,
    action: ServiceActionTypes<RiskManagementStats>
): StoreServiceData<RiskManagementStats> =>
    createReducer(state, action, STATS_STORE, () => showErrorToast(action.error));

export default statsReducer;
