import {
    Answer,
    newQuestionWithAnswer,
    newQuestionWithAnswerAndCommentsAndDate,
    QuestionWithAnswer,
    QuestionWithAnswerAndCommentsAndDate
} from "../auditHelpers";

export interface PremisesTrainingAndStorageAudit {
    communalAreaQuestions: TrainingAndStorageCommunalAreaQuestions;
    kitchenQuestions: TrainingAndStorageKitchenQuestions;
    electricalQuestions: TrainingAndStorageElectricalQuestions;
    securityQuestions: TrainingAndStorageSecurityQuestions;
    lockerRoomQuestions: TrainingAndStorageLockerRoomQuestions;
    trainingRoomOneQuestions: TrainingAndStorageTrainingRoomQuestions;
    trainingRoomTwoQuestions: TrainingAndStorageTrainingRoomQuestions;
    stockRoomQuestions?: TrainingAndStorageStockRoomQuestions;
    immersiveSuiteQuestions?: TrainingAndStorageImmersiveSuiteQuestions;
    medicineManagementQuestions?: TrainingAndStorageMedicineManagementQuestions;
    officeQuestions: TrainingAndStorageOfficeQuestions;
}

interface TrainingAndStorageCommunalAreaQuestions {
    areasFreeFromAccumulatedMaterial: QuestionWithAnswer;
    heatersClearFromCombustibleMaterial: QuestionWithAnswer;
    accessAndExitsUnobstructed: QuestionWithAnswer;
    roomTemperatureAtAnAcceptableLevel: QuestionWithAnswer;
    floorCoveringsSecureAndFreeFromTrippingHazards: QuestionWithAnswer;
    communalFacilitiesInAdequateConditionOfRepair: QuestionWithAnswer;
    electricalExtensionLeadsCorrectlyInstalled: QuestionWithAnswer;
    adequateLightingLevelsInAllOfficeAreas: QuestionWithAnswer;
    firstAidKitAvailableAndReadilyAccessible: QuestionWithAnswer;
    communalPointsUnobstructedAndSecure: QuestionWithAnswer;
    kitchenAreaSurfacesClean: QuestionWithAnswer;
    drainFreeFromObviousBlockage: QuestionWithAnswer;
    handWashingFacilitiesAvailableIncludingSoapAndTowels: QuestionWithAnswer;
    handWashingInformationDisplayed: QuestionWithAnswer;
    confidentialWasteDisposedOfCorrectly: QuestionWithAnswer;
}

export const trainingAndStorageCommunalAreaQuestionOrder: string[] = [
    "areasFreeFromAccumulatedMaterial",
    "heatersClearFromCombustibleMaterial",
    "accessAndExitsUnobstructed",
    "roomTemperatureAtAnAcceptableLevel",
    "floorCoveringsSecureAndFreeFromTrippingHazards",
    "communalFacilitiesInAdequateConditionOfRepair",
    "electricalExtensionLeadsCorrectlyInstalled",
    "adequateLightingLevelsInAllOfficeAreas",
    "firstAidKitAvailableAndReadilyAccessible",
    "communalPointsUnobstructedAndSecure",
    "kitchenAreaSurfacesClean",
    "drainFreeFromObviousBlockage",
    "handWashingFacilitiesAvailableIncludingSoapAndTowels",
    "handWashingInformationDisplayed",
    "confidentialWasteDisposedOfCorrectly"
];

interface TrainingAndStorageKitchenQuestions {
    surfacesCleanAndFreeOfFoodWaste: QuestionWithAnswer;
    foodStoredCorrectly: QuestionWithAnswer;
    fridgeTemperatureBetween3and5: QuestionWithAnswer;
    floorsMoppedUsingCorrectColourCodedMopAndBuckets: QuestionWithAnswer;
    drainsFreeFromObviousBlockages: QuestionWithAnswer;
    electricalExtensionLeadsCorrectlyInstalled: QuestionWithAnswer;
    communalPointsUnobstructedAndSecure: QuestionWithAnswer;
    handWashingFacilitiesAvailableIncludingSoapAndTowels: QuestionWithAnswer;
    handWashingInformationDisplayed: QuestionWithAnswer;
    areaFreeFromPestsAndVermin: QuestionWithAnswer;
    recyclingBinsAvailable: QuestionWithAnswer;
    sufficientRefuseBinsAvailableAndUndamaged: QuestionWithAnswer;
    buildUpOfRubbishAroundBins: QuestionWithAnswer;
    binsEmptiedRegularly: QuestionWithAnswer;
}
export const trainingAndStorageKitchenQuestionOrder: string[] = [
    "surfacesCleanAndFreeOfFoodWaste",
    "foodStoredCorrectly",
    "fridgeTemperatureBetween3and5",
    "floorsMoppedUsingCorrectColourCodedMopAndBuckets",
    "drainsFreeFromObviousBlockages",
    "electricalExtensionLeadsCorrectlyInstalled",
    "communalPointsUnobstructedAndSecure",
    "handWashingFacilitiesAvailableIncludingSoapAndTowels",
    "handWashingInformationDisplayed",
    "areaFreeFromPestsAndVermin",
    "recyclingBinsAvailable",
    "sufficientRefuseBinsAvailableAndUndamaged",
    "buildUpOfRubbishAroundBins",
    "binsEmptiedRegularly"
];

interface TrainingAndStorageElectricalQuestions {
    electricalAppliancesRegularlyPatTested: QuestionWithAnswer;
    allStickersVisibleOnAppliances: QuestionWithAnswer;
    dateOfLastPatCheck: QuestionWithAnswerAndCommentsAndDate;
}

export const trainingAndStorageElectricalQuestionOrder: string[] = [
    "electricalAppliancesRegularlyPatTested",
    "allStickersVisibleOnAppliances",
    "dateOfLastPatCheck"
];

interface TrainingAndStorageSecurityQuestions {
    doorsAndWindowsLocked: QuestionWithAnswer;
    alarmDetectionSystemTestedAndWorking: QuestionWithAnswer;
    vegetationDoesNotObscureVisibility: QuestionWithAnswer;
    perimeterFencingWallsGatesAreInGoodRepair: QuestionWithAnswer;
    externalWheelieBinsAreLocked: QuestionWithAnswer;
    cctvSystemsAreInWorkingOrder: QuestionWithAnswer;
    confidentialMaterialSecurelyKept: QuestionWithAnswer;
}

export const trainingAndStorageSecurityQuestionOrder: string[] = [
    "doorsAndWindowsLocked",
    "alarmDetectionSystemTestedAndWorking",
    "vegetationDoesNotObscureVisibility",
    "perimeterFencingWallsGatesAreInGoodRepair",
    "externalWheelieBinsAreLocked",
    "cctvSystemsAreInWorkingOrder",
    "confidentialMaterialSecurelyKept"
];

interface TrainingAndStorageLockerRoomQuestions {
    surfacesClean: QuestionWithAnswer;
    floorsMoppedUsingCorrectColourCodedMopAndBuckets: QuestionWithAnswer;
    handWashingFacilitiesAvailableIncludingSoapAndTowels: QuestionWithAnswer;
    handWashingInformationDisplayed: QuestionWithAnswer;
    toiletAndSinkRegularlyCleaned: QuestionWithAnswer;
    drainsAndToiletFreeFromObviousBlockage: QuestionWithAnswer;
    lockersSecureAndKeysAvailable: QuestionWithAnswer;
}

export const trainingAndStorageLockerRoomQuestionOrder: string[] = [
    "surfacesClean",
    "floorsMoppedUsingCorrectColourCodedMopAndBuckets",
    "handWashingFacilitiesAvailableIncludingSoapAndTowels",
    "handWashingInformationDisplayed",
    "toiletAndSinkRegularlyCleaned",
    "drainsAndToiletFreeFromObviousBlockage",
    "lockersSecureAndKeysAvailable"
];

interface TrainingAndStorageTrainingRoomQuestions {
    areasFreeFromAccumulatedMaterial: QuestionWithAnswer;
    heatersClearFromCombustibleMaterial: QuestionWithAnswer;
    accessAndExitsUnobstructed: QuestionWithAnswer;
    roomTemperatureAtAnAcceptableLevel: QuestionWithAnswer;
    floorCoveringsSecureAndFreeFromTrippingHazards: QuestionWithAnswer;
    communalFacilitiesInAdequateConditionOfRepair: QuestionWithAnswer;
    electricalExtensionLeadsCorrectlyInstalled: QuestionWithAnswer;
    adequateLightingLevelsInAllOfficeAreas: QuestionWithAnswer;
    firstAidKitAvailableAndReadilyAccessible: QuestionWithAnswer;
    communalPointsUnobstructedAndSecure: QuestionWithAnswer;
}
export const trainingAndStorageTrainingRoomQuestionOrder: string[] = [
    "areasFreeFromAccumulatedMaterial",
    "heatersClearFromCombustibleMaterial",
    "roomTemperatureAtAnAcceptableLevel",
    "floorCoveringsSecureAndFreeFromTrippingHazards",
    "communalFacilitiesInAdequateConditionOfRepair",
    "electricalExtensionLeadsCorrectlyInstalled",
    "adequateLightingLevelsInAllOfficeAreas",
    "firstAidKitAvailableAndReadilyAccessible",
    "communalPointsUnobstructedAndSecure"
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TrainingAndStorageStockRoomQuestions {
    //
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TrainingAndStorageImmersiveSuiteQuestions {
    //
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TrainingAndStorageMedicineManagementQuestions {
    //
}

interface TrainingAndStorageOfficeQuestions {
    areasFreeFromAccumulatedMaterial: QuestionWithAnswer;
    heatersClearFromCombustibleMaterial: QuestionWithAnswer;
    accessAndExitsUnobstructed: QuestionWithAnswer;
    roomTemperatureAtAnAcceptableLevel: QuestionWithAnswer;
    floorCoveringsSecureAndFreeFromTrippingHazards: QuestionWithAnswer;
    communalFacilitiesInAdequateConditionOfRepair: QuestionWithAnswer;
    electricalExtensionLeadsCorrectlyInstalled: QuestionWithAnswer;
    adequateLightingLevelsInAllOfficeAreas: QuestionWithAnswer;
    firstAidKitAvailableAndReadilyAccessible: QuestionWithAnswer;
    communalPointsUnobstructedAndSecure: QuestionWithAnswer;
    confidentialWasteDisposedOfCorrectly: QuestionWithAnswer;
}
export const trainingAndStorageOfficeQuestionOrder: string[] = [
    "areasFreeFromAccumulatedMaterial",
    "heatersClearFromCombustibleMaterial",
    "accessAndExitsUnobstructed",
    "roomTemperatureAtAnAcceptableLevel",
    "floorCoveringsSecureAndFreeFromTrippingHazards",
    "communalFacilitiesInAdequateConditionOfRepair",
    "electricalExtensionLeadsCorrectlyInstalled",
    "adequateLightingLevelsInAllOfficeAreas",
    "firstAidKitAvailableAndReadilyAccessible",
    "communalPointsUnobstructedAndSecure",
    "confidentialWasteDisposedOfCorrectly"
];

export function generateBlankPremisesTrainingAndStorageAudit(): PremisesTrainingAndStorageAudit {
    return {
        communalAreaQuestions: generateCommunalQuestions(),
        kitchenQuestions: generateKitchenQuestion(),
        electricalQuestions: generateElectricalQuestions(),
        securityQuestions: generateSecurityQuestions(),
        lockerRoomQuestions: generateLockerRoomQuestions(),
        trainingRoomOneQuestions: generateTrainingRoomQuestions(),
        trainingRoomTwoQuestions: generateTrainingRoomQuestions(),
        officeQuestions: generateOfficeQuestions()
    };
}

function generateCommunalQuestions(): TrainingAndStorageCommunalAreaQuestions {
    return {
        areasFreeFromAccumulatedMaterial: newQuestionWithAnswer(
            "Areas free from accumulated material?",
            Answer.NotApplicable,
            2
        ),
        heatersClearFromCombustibleMaterial: newQuestionWithAnswer(
            "Heaters clear from combustible material?",
            Answer.NotApplicable,
            2
        ),
        accessAndExitsUnobstructed: newQuestionWithAnswer(
            "Access and exits unobstructed?",
            Answer.NotApplicable,
            2
        ),
        roomTemperatureAtAnAcceptableLevel: newQuestionWithAnswer(
            "Room temperature at an acceptable level (min 16°)?",
            Answer.NotApplicable,
            2
        ),
        floorCoveringsSecureAndFreeFromTrippingHazards: newQuestionWithAnswer(
            "Floor coverings secure and free from tripping hazards?",
            Answer.NotApplicable,
            2
        ),
        communalFacilitiesInAdequateConditionOfRepair: newQuestionWithAnswer(
            "Communal facilities in adequate condition of repair?",
            Answer.NotApplicable,
            2
        ),
        electricalExtensionLeadsCorrectlyInstalled: newQuestionWithAnswer(
            "Electrical extension leads correctly installed?",
            Answer.NotApplicable,
            5
        ),
        adequateLightingLevelsInAllOfficeAreas: newQuestionWithAnswer(
            "Adequate lighting levels in all office areas?",
            Answer.NotApplicable,
            2
        ),
        firstAidKitAvailableAndReadilyAccessible: newQuestionWithAnswer(
            "First aid kit available and readily accessible?",
            Answer.NotApplicable,
            2
        ),
        communalPointsUnobstructedAndSecure: newQuestionWithAnswer(
            "Communal points unobstructed and secure?",
            Answer.NotApplicable,
            5
        ),
        kitchenAreaSurfacesClean: newQuestionWithAnswer(
            "Kitchen area surfaces clean?",
            Answer.NotApplicable,
            2
        ),
        drainFreeFromObviousBlockage: newQuestionWithAnswer(
            "Drain free from obvious blockages ?",
            Answer.NotApplicable,
            2
        ),
        handWashingFacilitiesAvailableIncludingSoapAndTowels: newQuestionWithAnswer(
            "Hand washing facilities available including soap and towels?",
            Answer.NotApplicable,
            5
        ),
        handWashingInformationDisplayed: newQuestionWithAnswer(
            "Hand washing information displayed?",
            Answer.NotApplicable,
            5
        ),
        confidentialWasteDisposedOfCorrectly: newQuestionWithAnswer(
            "Confidential waste disposed of correctly?",
            Answer.NotApplicable,
            8
        )
    };
}

function generateKitchenQuestion(): TrainingAndStorageKitchenQuestions {
    return {
        surfacesCleanAndFreeOfFoodWaste: newQuestionWithAnswer(
            "Surfaces clean and free of food/waste?",
            Answer.NotApplicable,
            2
        ),
        foodStoredCorrectly: newQuestionWithAnswer(
            "Food stored correctly?",
            Answer.NotApplicable,
            2
        ),
        fridgeTemperatureBetween3and5: newQuestionWithAnswer(
            "Fridge temperature between 3° and 5°?",
            Answer.NotApplicable,
            8
        ),
        floorsMoppedUsingCorrectColourCodedMopAndBuckets: newQuestionWithAnswer(
            "Floors mopped using correct coloured coded mop and buckets?",
            Answer.NotApplicable,
            2
        ),
        drainsFreeFromObviousBlockages: newQuestionWithAnswer(
            "Drains free from obvious blockages?",
            Answer.NotApplicable,
            2
        ),
        electricalExtensionLeadsCorrectlyInstalled: newQuestionWithAnswer(
            "Electrical extension leads correctly installed?",
            Answer.NotApplicable,
            5
        ),
        communalPointsUnobstructedAndSecure: newQuestionWithAnswer(
            "Communal points unobstructed and secure?",
            Answer.NotApplicable,
            5
        ),
        handWashingFacilitiesAvailableIncludingSoapAndTowels: newQuestionWithAnswer(
            "Hand washing facilities available including soap and towels?",
            Answer.NotApplicable,
            5
        ),
        handWashingInformationDisplayed: newQuestionWithAnswer(
            "Hand washing information displayed?",
            Answer.NotApplicable,
            5
        ),
        areaFreeFromPestsAndVermin: newQuestionWithAnswer(
            "Area free from vermin/pests?",
            Answer.NotApplicable,
            5
        ),
        recyclingBinsAvailable: newQuestionWithAnswer(
            "Recycling bins available?",
            Answer.NotApplicable,
            2
        ),
        sufficientRefuseBinsAvailableAndUndamaged: newQuestionWithAnswer(
            "Sufficient refuse bins available and undamaged?",
            Answer.NotApplicable,
            2
        ),
        buildUpOfRubbishAroundBins: newQuestionWithAnswer(
            "Build up of rubbish around bins?",
            Answer.NotApplicable,
            2
        ),
        binsEmptiedRegularly: newQuestionWithAnswer(
            "Bins emptied regularly?",
            Answer.NotApplicable,
            2
        )
    };
}

function generateElectricalQuestions(): TrainingAndStorageElectricalQuestions {
    return {
        electricalAppliancesRegularlyPatTested: newQuestionWithAnswer(
            "Electrical appliances regularly PAT tested?",
            Answer.NotApplicable,
            5
        ),
        allStickersVisibleOnAppliances: newQuestionWithAnswer(
            "All test stickers/labels visible on appliances?",
            Answer.NotApplicable,
            5
        ),
        dateOfLastPatCheck: newQuestionWithAnswerAndCommentsAndDate(
            "Date of last PAT test check?",
            0,
            Answer.NotApplicable,
            "Date"
        )
    };
}

function generateSecurityQuestions(): TrainingAndStorageSecurityQuestions {
    return {
        doorsAndWindowsLocked: newQuestionWithAnswer(
            "Doors and windows locked (OOH)?",
            Answer.NotApplicable,
            5
        ),
        alarmDetectionSystemTestedAndWorking: newQuestionWithAnswer(
            "Alarm detection systems tested and working?",
            Answer.NotApplicable,
            5
        ),
        vegetationDoesNotObscureVisibility: newQuestionWithAnswer(
            "Vegetation does not obscure visibility and is well maintained?",
            Answer.NotApplicable,
            2
        ),
        perimeterFencingWallsGatesAreInGoodRepair: newQuestionWithAnswer(
            "Perimeter fencing/walls/gates are in good repair?",
            Answer.NotApplicable,
            2
        ),
        externalWheelieBinsAreLocked: newQuestionWithAnswer(
            "External wheelie-bins are locked?",
            Answer.NotApplicable,
            2
        ),
        cctvSystemsAreInWorkingOrder: newQuestionWithAnswer(
            "CCTV systems in working order?",
            Answer.NotApplicable,
            5
        ),
        confidentialMaterialSecurelyKept: newQuestionWithAnswer(
            "Confidential material securely kept?",
            Answer.NotApplicable,
            8
        )
    };
}

function generateLockerRoomQuestions(): TrainingAndStorageLockerRoomQuestions {
    return {
        surfacesClean: newQuestionWithAnswer("Surfaces clean?", Answer.NotApplicable, 2),
        floorsMoppedUsingCorrectColourCodedMopAndBuckets: newQuestionWithAnswer(
            "Floors mopped using correct coloured coded mop and buckets?",
            Answer.NotApplicable,
            2
        ),
        handWashingFacilitiesAvailableIncludingSoapAndTowels: newQuestionWithAnswer(
            "Hand washing facilities available including soap and towels?",
            Answer.NotApplicable,
            5
        ),
        handWashingInformationDisplayed: newQuestionWithAnswer(
            "Hand washing information displayed?",
            Answer.NotApplicable,
            5
        ),
        toiletAndSinkRegularlyCleaned: newQuestionWithAnswer(
            "Toilet and sinks regularly cleaned?",
            Answer.NotApplicable,
            2
        ),
        drainsAndToiletFreeFromObviousBlockage: newQuestionWithAnswer(
            "Drains and toilets free from obvious blockage?",
            Answer.NotApplicable,
            2
        ),
        lockersSecureAndKeysAvailable: newQuestionWithAnswer(
            "Lockers secure and keys available?",
            Answer.NotApplicable,
            5
        )
    };
}

function generateTrainingRoomQuestions(): TrainingAndStorageTrainingRoomQuestions {
    return {
        areasFreeFromAccumulatedMaterial: newQuestionWithAnswer(
            "Areas free from accumulated material?",
            Answer.NotApplicable,
            2
        ),
        heatersClearFromCombustibleMaterial: newQuestionWithAnswer(
            "Heaters clear from combustible material?",
            Answer.NotApplicable,
            2
        ),
        accessAndExitsUnobstructed: newQuestionWithAnswer(
            "Access and exits unobstructed?",
            Answer.NotApplicable,
            2
        ),
        roomTemperatureAtAnAcceptableLevel: newQuestionWithAnswer(
            "Room temperature at an acceptable level (min 16°)?",
            Answer.NotApplicable,
            2
        ),
        floorCoveringsSecureAndFreeFromTrippingHazards: newQuestionWithAnswer(
            "Floor coverings secure and free from tripping hazards?",
            Answer.NotApplicable,
            2
        ),
        communalFacilitiesInAdequateConditionOfRepair: newQuestionWithAnswer(
            "Communal facilities in adequate condition of repair?",
            Answer.NotApplicable,
            2
        ),
        electricalExtensionLeadsCorrectlyInstalled: newQuestionWithAnswer(
            "Electrical extension leads correctly installed?",
            Answer.NotApplicable,
            5
        ),
        adequateLightingLevelsInAllOfficeAreas: newQuestionWithAnswer(
            "Adequate lighting levels in all office areas?",
            Answer.NotApplicable,
            2
        ),
        firstAidKitAvailableAndReadilyAccessible: newQuestionWithAnswer(
            "First aid kit available and readily accessible?",
            Answer.NotApplicable,
            2
        ),
        communalPointsUnobstructedAndSecure: newQuestionWithAnswer(
            "Communal points unobstructed and secure?",
            Answer.NotApplicable,
            5
        )
    };
}

function generateOfficeQuestions(): TrainingAndStorageOfficeQuestions {
    return {
        areasFreeFromAccumulatedMaterial: newQuestionWithAnswer(
            "Areas free from accumulated material?",
            Answer.NotApplicable,
            2
        ),
        heatersClearFromCombustibleMaterial: newQuestionWithAnswer(
            "Heaters clear from combustible material?",
            Answer.NotApplicable,
            2
        ),
        accessAndExitsUnobstructed: newQuestionWithAnswer(
            "Access and exits unobstructed?",
            Answer.NotApplicable,
            2
        ),
        roomTemperatureAtAnAcceptableLevel: newQuestionWithAnswer(
            "Room temperature at an acceptable level (min 16°)?",
            Answer.NotApplicable,
            2
        ),
        floorCoveringsSecureAndFreeFromTrippingHazards: newQuestionWithAnswer(
            "Floor coverings secure and free from tripping hazards?",
            Answer.NotApplicable,
            2
        ),
        communalFacilitiesInAdequateConditionOfRepair: newQuestionWithAnswer(
            "Communal facilities in adequate condition of repair?",
            Answer.NotApplicable,
            2
        ),
        electricalExtensionLeadsCorrectlyInstalled: newQuestionWithAnswer(
            "Electrical extension leads correctly installed?",
            Answer.NotApplicable,
            5
        ),
        adequateLightingLevelsInAllOfficeAreas: newQuestionWithAnswer(
            "Adequate lighting levels in all office areas?",
            Answer.NotApplicable,
            2
        ),
        firstAidKitAvailableAndReadilyAccessible: newQuestionWithAnswer(
            "First aid kit available and readily accessible?",
            Answer.NotApplicable,
            2
        ),
        communalPointsUnobstructedAndSecure: newQuestionWithAnswer(
            "Communal points unobstructed and secure?",
            Answer.NotApplicable,
            5
        ),
        confidentialWasteDisposedOfCorrectly: newQuestionWithAnswer(
            "Confidential waste disposed of correctly?",
            Answer.NotApplicable,
            8
        )
    };
}
