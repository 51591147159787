import {
    Answer,
    newQuestionWithAnswerAndComments,
    newSectionDetailItem,
    QuestionWithAnswerAndComments,
    SectionDetails
} from "../auditHelpers";

export interface MonthlyFireSafetyAudit {
    emergencyLightingTested: QuestionWithAnswerAndComments;
    monthlyCheckDetails: SectionDetails;
}

export const fireSafetyMonthlyQuestionOrder: string[] = [
    "emergencyLightingTested",
    "monthlyCheckDetails"
];

export function generateBlankMonthlyFireSafetyAudit(): MonthlyFireSafetyAudit {
    return {
        emergencyLightingTested: newQuestionWithAnswerAndComments(
            "Emergency lighting tested?",
            Answer.No
        ),
        monthlyCheckDetails: newSectionDetailItem("Monthly check comments/details")
    };
}
