import React, {useEffect, useState} from "react";
import {
    RiskAssessmentMatrix,
    RiskAssessmentMatrixRow
} from "../../../../../../store/audit/helpers/RiskAssessment/riskAssessmentMatrixHelpers";
import FormHeader from "../../../../../Form/FormHeader";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";
import {AuditType} from "../../../../../../api/rm";
import RiskAssessmentTableHeader from "./Components/RiskAssessmentTableHeader";
import FormActionContainer from "../../../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import {useDispatch, useSelector} from "react-redux";
import {addRowToRiskAssessmentMatrix} from "../../../../../../store/audit/actions/AuditActions";
import RiskAssessmentTableRow from "./Components/RiskAssessmentTableRow";
import {useIsHistoricAudit} from "../../../../../Hooks/useIsHistoricAudit";
import {triggerPrint} from "../../../../../Printable/Helpers/printableHelpers";
import PrintableRiskAssessmentMatrix from "./Components/Printable/PrintableRiskAssessmentMatrix";
import {RootStore} from "../../../../../../store/Store";
import {getVenueName} from "../../../../../../store/venue/actions/VenueActions";
import {getCategoryName} from "../../../../../../store/category/actions/CategoryActions";

const RiskAssessmentAuditForm = (props: RiskAssessmentMatrix) => {
    const [print, setPrint] = useState<boolean>(false);
    const dispatch = useDispatch();
    const isHistoric = useIsHistoricAudit();
    const categoryList = useSelector((state: RootStore) => state.categoryList.data);
    const venueList = useSelector((state: RootStore) => state.categoryList.data);
    const audit = useSelector((state: RootStore) => state.audit.data);
    const historicAudit = useSelector((state: RootStore) => state.historicAudit.data);

    const getCategoryId = () => {
        if (audit) {
            return audit.categoryId;
        }
        if (historicAudit) {
            return historicAudit.categoryId;
        }
        return -1;
    };

    const getVenueId = () => {
        if (audit) {
            return audit.venueId;
        }
        if (historicAudit) {
            return historicAudit.venueId;
        }
        return -1;
    };

    const onRowAdded = () => {
        dispatch(addRowToRiskAssessmentMatrix());
    };

    const onPrintRiskAssessmentMatrix = () => {
        setPrint(true);
    };

    useEffect(() => {
        const content = document.getElementById("printable");
        if (!content) return;
        triggerPrint(content);
        setTimeout(() => {
            setPrint(false);
        }, 1000);
    }, [print]);

    return (
        <React.Fragment>
            <FormHeader headerName={getUiFriendlyText(AuditType.RiskAssessmentMatrix)} />
            {props.rows.length > 0 ? (
                <React.Fragment>
                    <div className="row ml-0 mr-0 mb-0 mt-3">
                        <div className="col pr-0 d-flex justify-content-end">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Print Risk Assessment Matrix"}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                                onClick={onPrintRiskAssessmentMatrix}
                            />
                        </div>
                    </div>
                    <div className="row ml-0 mr-0 risk-assessment-table-wrapper">
                        <div className="d-block table-overflow-x">
                            <table className="mc-table fadeIn mb-5">
                                <RiskAssessmentTableHeader />
                                {props.rows.map((item: RiskAssessmentMatrixRow, index) => {
                                    return <RiskAssessmentTableRow {...item} key={index} />;
                                })}
                            </table>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 mt-5">
                    <div className="col">
                        <h6 className="text-center">
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            There are no risks in this matrix. Click "Add Row" to add a new
                            assessment.
                        </h6>
                    </div>
                </div>
            )}
            {!isHistoric && (
                <FormActionContainer>
                    <div className="col d-grid">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Add Row"}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                            onClick={onRowAdded}
                        />
                    </div>
                </FormActionContainer>
            )}
            {print && (
                <React.Fragment>
                    <div id="printable" className="d-none">
                        <PrintableRiskAssessmentMatrix
                            matrix={props}
                            venueName={getVenueName(getVenueId(), venueList)}
                            categoryName={getCategoryName(getCategoryId(), categoryList)}
                        />
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default RiskAssessmentAuditForm;
