import React from "react";
import {SectionDetails} from "../../store/audit/helpers/auditHelpers";
import {useIsHistoricAudit} from "../Hooks/useIsHistoricAudit";
import FormRow from "./FormRow";
import {DebounceInput} from "react-debounce-input";

const SectionDetailsItem = (props: SectionDetailsWithCallback) => {
    const isHistoric = useIsHistoricAudit();

    return (
        <React.Fragment>
            <FormRow
                rowName={props.sectionName}
                columnDetailClassName={!isHistoric ? "pl-0 pr-0" : ""}
            >
                {!isHistoric ? (
                    <DebounceInput
                        debounceTimeout={300}
                        className="input-fields text-area-inputs"
                        element={"textarea"}
                        style={{minHeight: `150px`}}
                        value={props.details}
                        onChange={(event) => {
                            const {value} = event.target;

                            props.onChange({
                                ...props,
                                details: value
                            });
                        }}
                        placeholder="Enter details here..."
                    />
                ) : (
                    <p className="body-font">{props.details}</p>
                )}
            </FormRow>
        </React.Fragment>
    );
};

export default SectionDetailsItem;

interface SectionDetailsWithCallback extends SectionDetails {
    rowClassName?: string;
    onChange: (updatedQuestion: SectionDetails) => void;
}
