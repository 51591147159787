import {MatrixRating} from "../../../../../../../store/audit/helpers/RiskAssessment/riskAssessmentMatrixHelpers";
import React from "react";

const MatrixRatingTable = (props: MatrixRatingTableProps) => {
    const getMatrixRatingTable = () => {
        const {severity, likelihood} = props.rating.severityLikelihoodMatrix;
        return severity * likelihood;
    };

    const getColourForTotal = () => {
        const total = getMatrixRatingTable();

        if (total < 6) {
            return "green-rated";
        }
        if (total < 11) {
            return "yellow-rated";
        }
        if (total < 15) {
            return "amber-rated";
        }
        if (total < 21) {
            return "orange-rated";
        }
        return "red-rated";
    };
    return (
        <table className="mc-table">
            <tbody>
                <tr className="mc-table-row">
                    <td className="mc-table-row-item">
                        <input
                            className="input-fields min-width-50px with-border-right text-center"
                            value={props.rating.severityLikelihoodMatrix.severity}
                            onChange={(event) => {
                                const severity = +event.target.value;
                                const updatedRating: MatrixRating = {
                                    ...props.rating,
                                    severityLikelihoodMatrix: {
                                        ...props.rating.severityLikelihoodMatrix,
                                        severity
                                    }
                                };

                                props.onChange(updatedRating);
                            }}
                            type={"tel"}
                            min={0}
                            max={6}
                            placeholder={"Enter severity"}
                            disabled={props.severityDisabled}
                        />
                    </td>
                    <td className="mc-table-row-item">
                        <input
                            className="input-fields min-width-50px with-border-right text-center"
                            value={props.rating.severityLikelihoodMatrix.likelihood}
                            onChange={(event) => {
                                const likelihood = +event.target.value;
                                const updatedRating: MatrixRating = {
                                    ...props.rating,
                                    severityLikelihoodMatrix: {
                                        ...props.rating.severityLikelihoodMatrix,
                                        likelihood
                                    }
                                };

                                props.onChange(updatedRating);
                            }}
                            type={"tel"}
                            min={0}
                            max={6}
                            placeholder={"Enter likelihood"}
                            disabled={props.likelihoodDisabled}
                        />
                    </td>
                    <td className={`mc-table-row-item ${getColourForTotal()}`} align={"center"}>
                        {getMatrixRatingTable()}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default MatrixRatingTable;

interface MatrixRatingTableProps {
    rating: MatrixRating;
    onChange: (newRating: MatrixRating) => void;
    severityDisabled: boolean;
    likelihoodDisabled: boolean;
}
