import React from "react";
import {AuditForm} from "../../../../../../store/audit/helpers/auditHelpers";
import FireSafetyAuditForm from "../FireSafetyAudits/FireSafety/FireSafetyAuditForm";
import PremisesDepotAuditForm from "../PremisesAudits/PremisesDepot/PremisesDepotAuditForm";
import PremisesTrainingAndSafetyAuditForm from "../PremisesAudits/PremisesTrainingAndSafety/PremisesTrainingAndSafetyAuditForm";
import PremisesHeadOfficeAuditForm from "../PremisesAudits/PremisesHeadOffice/PremisesHeadOfficeAuditForm";
import RiskAssessmentAuditForm from "../RiskAssessment/RiskAssessmentAuditForm";
import WeeklyFireAudit from "../FireSafetyAudits/Weekly/WeeklyFireAudit";
import MonthlyFireAudit from "../FireSafetyAudits/Monthly/MonthlyFireAudit";
import BiAnnualFireAudit from "../FireSafetyAudits/BiAnnual/BiAnnualFireAudit";
import AnnualFireAudit from "../FireSafetyAudits/Annual/AnnualFireAudit";
import FiveYearFireAudit from "../FireSafetyAudits/FiveYear/FiveYearFireAudit";

const AuditFormContainer = (props: AuditForm) => {
    return (
        <React.Fragment>
            {props.riskAssessmentMatrixAudit && (
                <RiskAssessmentAuditForm {...props.riskAssessmentMatrixAudit} />
            )}
            {props.premisesDepotAudit && <PremisesDepotAuditForm {...props.premisesDepotAudit} />}
            {props.premisesHeadOfficeAudit && (
                <PremisesHeadOfficeAuditForm {...props.premisesHeadOfficeAudit} />
            )}
            {props.premisesTrainingStorageAreaAudit && (
                <PremisesTrainingAndSafetyAuditForm {...props.premisesTrainingStorageAreaAudit} />
            )}
            {props.fireSafetyAudit && <FireSafetyAuditForm {...props.fireSafetyAudit} />}
            {props.weeklyFireSafetyAudit && <WeeklyFireAudit {...props.weeklyFireSafetyAudit} />}
            {props.monthlyFireSafetyAudit && <MonthlyFireAudit {...props.monthlyFireSafetyAudit} />}
            {props.biAnnualFireSafetyAudit && (
                <BiAnnualFireAudit {...props.biAnnualFireSafetyAudit} />
            )}
            {props.annualFireSafetyAudit && <AnnualFireAudit {...props.annualFireSafetyAudit} />}
            {props.fiveYearFireSafetyAudit && (
                <FiveYearFireAudit {...props.fiveYearFireSafetyAudit} />
            )}
        </React.Fragment>
    );
};

export default AuditFormContainer;
