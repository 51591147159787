import React from "react";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {AuditType} from "../../../../../../../api/rm";
import QuestionBlock from "../../General/QuestionBlock";
import {
    fireSafetyMonthlyQuestionOrder,
    MonthlyFireSafetyAudit
} from "../../../../../../../store/audit/helpers/FireSafety/monthlyFireSafetyAudit";

const MonthlyFireAudit = (props: MonthlyFireSafetyAudit) => {
    return (
        <React.Fragment>
            <QuestionBlock
                headerName={getUiFriendlyText(AuditType.AuditMonthlyFireSafety)}
                showYesNoAnswerOnly={true}
                nestingList={["monthlyFireSafetyAudit"]}
                checklist={props}
                questionOrder={fireSafetyMonthlyQuestionOrder}
            />
        </React.Fragment>
    );
};

export default MonthlyFireAudit;
