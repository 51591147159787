import {Dispatch} from "redux";
import {HISTORIC_AUDIT_STORE, HistoricAuditDispatchTypes} from "./HistoricAuditActionTypes";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import RiskManagementApiModel from "../../apiModel/RiskManagementApiModel";
import store from "../../Store";
import {getAllCategories} from "../../categoryList/actions/CategoryListActions";
import {getAllVenues} from "../../venueList/actions/VenueListActions";
import {getAllMedicareStaffMembers} from "../../staffList/actions/StaffListActions";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyHistoricAuditStore = () => {
    return async (dispatch: Dispatch<HistoricAuditDispatchTypes>) => {
        dispatch({
            type: HISTORIC_AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const getHistoricAuditForLiveAudit = (auditId: number, historicId: number) => {
    return async (dispatch: Dispatch<HistoricAuditDispatchTypes>) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getAllMedicareStaffMembers());

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getAllCategories());

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getAllVenues());
            return await getDataFromServiceWithRedux(
                HISTORIC_AUDIT_STORE,
                dispatch,
                () => RiskManagementApiModel.getRmApi().getHistoricAudit(auditId, historicId),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: HISTORIC_AUDIT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
