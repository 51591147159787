import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {HistoricAudit} from "../../../api/rm";

export const HISTORIC_AUDIT_STORE = createStoreState("historic_audit_store");

interface HistoricAuditLoading extends StoreServiceData<HistoricAudit> {
    type: typeof HISTORIC_AUDIT_STORE.LOADING;
}

interface HistoricAuditError extends StoreServiceData<HistoricAudit> {
    type: typeof HISTORIC_AUDIT_STORE.ERROR;
}

interface HistoricAuditSuccess extends StoreServiceData<HistoricAudit> {
    type: typeof HISTORIC_AUDIT_STORE.SUCCESS;
}

export type HistoricAuditDispatchTypes =
    | HistoricAuditLoading
    | HistoricAuditError
    | HistoricAuditSuccess;
