import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import RiskManagementStats from "./Components/RiskManagementStats";

const Overview = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.venueList.name,
                path: routeNames.venueList.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));
    }, []);
    return (
        <React.Fragment>
            <RiskManagementStats />
        </React.Fragment>
    );
};

export default Overview;
