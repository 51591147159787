import {
    Answer,
    newQuestionWithAnswerAndCommentsAndDate,
    newSectionDetailItem,
    QuestionWithAnswerAndCommentsAndDate,
    SectionDetails
} from "../auditHelpers";

export interface BiAnnualFireSafetyAudit {
    fireDrillActionedDetails: QuestionWithAnswerAndCommentsAndDate;
    fireAlarmPanelDetails: QuestionWithAnswerAndCommentsAndDate;
    smokeDetectorsDetails: QuestionWithAnswerAndCommentsAndDate;
    biannualCheckDetails: SectionDetails;
}
export const fireSafetyBiAnnualQuestionOrder: string[] = [
    "fireDrillActionedDetails",
    "fireAlarmPanelDetails",
    "smokeDetectorsDetails",
    "biannualCheckDetails"
];

export function generateBlankBiAnnualFireSafetyAudit(): BiAnnualFireSafetyAudit {
    return {
        fireDrillActionedDetails: newQuestionWithAnswerAndCommentsAndDate(
            "Fire drill actioned?",
            0,
            Answer.No,
            "Date"
        ),
        fireAlarmPanelDetails: newQuestionWithAnswerAndCommentsAndDate(
            "Fire Alarm Panel?",
            0,
            Answer.No,
            "Service Date:"
        ),
        smokeDetectorsDetails: newQuestionWithAnswerAndCommentsAndDate(
            "Smoke Detectors?",
            0,
            Answer.No,
            "Service Date:"
        ),
        biannualCheckDetails: newSectionDetailItem("Bi-Annual check comments/details")
    };
}
