import React from "react";
import halfDonut from "../../images/HalfDonutScale.png";
import needle from "../../images/DonutNeedle.png";
import {StatisticsItem} from "./Helpers/statsHelpers";
import Tooltip from "../Tooltip/Tooltip";

const HalfDonut = (props: StatisticsItem) => (
    <React.Fragment>
        <div className="stat-item">
            <div className="row">
                <div className="col d-flex position-relative justify-content-center">
                    <Tooltip
                        tooltipText={props.tooltipText}
                        wrapperClassName="cursor-pointer"
                        size={"lg"}
                        place={"bottom"}
                        theme={"dark"}
                    >
                        <img className="half-donut" src={halfDonut} alt="" />

                        <div
                            style={{
                                transform: `rotateZ(${props.needleAngle}deg)`
                            }}
                            className="donut-needle-parent"
                        >
                            <img className="donut-needle" src={needle} alt="" />
                        </div>
                    </Tooltip>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p className="outer-label-text">{props.outerLabel}</p>
                </div>
            </div>
        </div>
    </React.Fragment>
);

export default HalfDonut;
