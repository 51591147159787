import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {Audit} from "../../../api/rm";
import {AUDIT_STORE} from "../actions/AuditActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<Audit>(null);

/** Infer type of the reducer for code completion and quality */
const auditReducer = (
    state: StoreServiceData<Audit> = defaultState,
    action: ServiceActionTypes<Audit>
): StoreServiceData<Audit> =>
    createReducer(state, action, AUDIT_STORE, () => showErrorToast(action.error));

export default auditReducer;
