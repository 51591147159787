import React, {useState} from "react";
import {
    HistoricAuditListEntry,
    HistoricAuditListRequest,
    HistoricAuditListResponse
} from "../../../../api/rm";
import FilterContainer from "../../../Filters/FilterContainer";
import AuditListHistoricPagedFilters from "./AuditListHistoricPagedFilters";
import {useDispatch} from "react-redux";
import {fetchHistoricAuditListPaged} from "../../../../store/auditHistoricListPaged/actions/AuditListHistoricPagedActions";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import {pagedRequestConfig} from "../../../Filters/helpers/filterHelpers";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import Tooltip from "../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../Icon/Icon";
import {PulseTableWithServerPagination} from "pulse_table";
import {routeNames} from "../../../Navigation/routeNames";
import {useHistory} from "react-router-dom";

const HistoricAuditListTable = (props: HistoricAuditListResponse) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [auditListHistoricRequest, setAuditListHistoricRequest] =
        useState<HistoricAuditListRequest>();

    const onRequestChanged = (request: HistoricAuditListRequest) => {
        if (!auditListHistoricRequest) {
            setAuditListHistoricRequest(request);
            fetchPagedAudits(request);
            return;
        }
        //Ensures service isn't hammered with requests because of state updating
        if (JSON.stringify(request) === JSON.stringify(auditListHistoricRequest)) return;

        fetchPagedAudits(request);
        setAuditListHistoricRequest(request);
    };

    // Fetches Audits
    const fetchPagedAudits = (request: HistoricAuditListRequest) => {
        dispatch(fetchHistoricAuditListPaged(request));
    };

    const viewAuditHistory = (item: TableRow) => {
        const path = `${routeNames.auditHistoricView.path}/${item.actions.auditId}`;
        history.push({
            pathname: path,
            search: `historicId=${item.actions.historicId}`
        });
    };
    return (
        <React.Fragment>
            <FilterContainer closed={false}>
                <AuditListHistoricPagedFilters onRequestChanged={onRequestChanged} />
            </FilterContainer>
            <PulseTableWithServerPagination
                resultsPerPage={pagedRequestConfig.resultsPerPage}
                totalResults={props.totalEntries}
                onPageChanged={(newPageNum) => {
                    if (!auditListHistoricRequest) return;
                    fetchPagedAudits({
                        ...auditListHistoricRequest,
                        pageNum: newPageNum
                    });
                }}
                items={toTableRow(props)}
                headers={{
                    author: "Author",
                    dateModified: "Date Modified",
                    actions: "Actions"
                }}
                noItemsSection={
                    <NoItemsMessage
                        message={
                            "There are no historic audits for this audit matching these filters"
                        }
                    />
                }
                customRenderers={{
                    actions: (item: TableRow) => (
                        <React.Fragment>
                            <div className="row ml-0 mr-0">
                                <div className="icon-tooltip-wrapper-md mr-3">
                                    <Tooltip
                                        tooltipText={"View Historic Audit Version"}
                                        size={"md"}
                                        place={"left"}
                                    >
                                        <Icon
                                            rootElement={"Div"}
                                            icon={IconType.ViewHistory}
                                            size={"Medium"}
                                            className="icon-dark"
                                            onClick={() => viewAuditHistory(item)}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }}
            />
        </React.Fragment>
    );
};

export default HistoricAuditListTable;

interface TableRow {
    author: string;
    dateModified: string;
    actions: HistoricAuditListEntry;
}

function toTableRow(props: HistoricAuditListResponse): TableRow[] {
    return props.results.map((item) => {
        return {
            author: item.authorName,
            dateModified: formatUnixToDDMMYYYY(item.date),
            actions: item
        };
    });
}
