import React, {useRef} from "react";
import BarChart from "../../../../Stats/BarChart";
import {mcDarkBlue01, mcDarkBlue02, mcRed01, mcYellow01} from "../../../../../utils/colourUtils";
import {getRandomNumber} from "../../../../../utils/mathUtils";
import FormHeader from "../../../../Form/FormHeader";

const AuditBarChart = ({chartName, statsObject, totalNewAudits, toolTipTextPrefix}: Props) => {
    const colours = useRef<string[]>(generateBarColours(250));
    return (
        <React.Fragment>
            <div className="pt-4 pb-4">
                <FormHeader headerName={chartName} />
                {Object.keys(statsObject).map((key: string, index: number) => {
                    return (
                        <BarChart
                            maxValue={totalNewAudits}
                            currentValue={statsObject[key]}
                            label={`${key} - (${statsObject[key]}/${totalNewAudits})`}
                            key={index}
                            barColour={colours.current[index]}
                            tooltipText={`${toolTipTextPrefix} ${key}: (${statsObject[key]}/${totalNewAudits})`}
                        />
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default AuditBarChart;

interface Props {
    chartName: string;
    statsObject: {[p: string]: number};
    totalNewAudits: number;
    toolTipTextPrefix: string;
}

export function generateBarColours(amountToGenerate: number): string[] {
    const colours: string[] = [];
    const colourList = [mcDarkBlue01, mcDarkBlue02, mcYellow01, mcRed01];
    for (let i = 0; i < amountToGenerate; ++i) {
        const randomNumber = getRandomNumber(0, 3);

        colours.push(colourList[randomNumber]);
    }
    return colours;
}
