import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {AuditListResponse} from "../../../api/rm";
import {AUDIT_LIST_PAGED_STORE} from "../actions/AuditListPagedActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<AuditListResponse>(null);

/** Infer type of the reducer for code completion and quality */
const auditListPagedReducer = (
    state: StoreServiceData<AuditListResponse> = defaultState,
    action: ServiceActionTypes<AuditListResponse>
): StoreServiceData<AuditListResponse> =>
    createReducer(state, action, AUDIT_LIST_PAGED_STORE, () => showErrorToast(action.error));

export default auditListPagedReducer;
