import React from "react";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {AuditType} from "../../../../../../../api/rm";
import QuestionBlock from "../../General/QuestionBlock";

import {
    AnnualFireSafetyAudit,
    fireSafetyAnnualQuestionOrder
} from "../../../../../../../store/audit/helpers/FireSafety/annualFireSafetyAudit";

const AnnualFireAudit = (props: AnnualFireSafetyAudit) => {
    return (
        <React.Fragment>
            <QuestionBlock
                headerName={getUiFriendlyText(AuditType.AuditAnnualFireSafety)}
                showYesNoAnswerOnly={true}
                nestingList={["annualFireSafetyAudit"]}
                checklist={props}
                questionOrder={fireSafetyAnnualQuestionOrder}
            />
        </React.Fragment>
    );
};

export default AnnualFireAudit;
