import React from "react";
import {ComponentChildrenProps} from "../../../utils/componentUtils";

const PrintableTableRow = ({rowType, children}: PrintableTableRowProps) => {
    const getStyles = () => {
        switch (rowType) {
            case "headerRow":
                return {
                    background: "#1a2a35",
                    borderBottom: "1px solid #1a2a35",
                    width: "100%"
                };
            case "bodyRow":
                return {
                    background: "#eeeeee",
                    borderBottom: "1px solid transparent",
                    width: "100%"
                };
        }
    };
    return <tr style={getStyles()}>{children}</tr>;
};

export default PrintableTableRow;

interface PrintableTableRowProps extends ComponentChildrenProps {
    rowType: RowType;
}

type RowType = "headerRow" | "bodyRow";
