import React from "react";
import FormHeader from "../../../../../../Form/FormHeader";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {AuditType} from "../../../../../../../api/rm";
import QuestionBlock from "../../General/QuestionBlock";
import {
    mainOfficeBoardRoomRoomQuestionOrder,
    mainOfficeElectricalQuestionOrder,
    mainOfficeExecutiveOfficeMeetingRoomQuestionOrder,
    mainOfficeKitchenQuestionOrder,
    mainOfficeSecurityQuestionOrder,
    PremisesHeadOfficeAudit
} from "../../../../../../../store/audit/helpers/Premises/premisesHeadOfficeAudit";

const PremisesHeadOfficeAuditForm = (props: PremisesHeadOfficeAudit) => {
    return (
        <React.Fragment>
            <FormHeader headerName={getUiFriendlyText(AuditType.AuditPremisesHeadOffice)} />
            <QuestionBlock
                headerName={"Main Office"}
                showYesNoAnswerOnly={false}
                checklist={props.mainOfficeQuestions}
                nestingList={["premisesHeadOfficeAudit", "mainOfficeQuestions"]}
                questionOrder={mainOfficeExecutiveOfficeMeetingRoomQuestionOrder}
            />
            <QuestionBlock
                headerName={"Board Room"}
                showYesNoAnswerOnly={false}
                checklist={props.boardroomQuestions}
                nestingList={["premisesHeadOfficeAudit", "boardroomQuestions"]}
                questionOrder={mainOfficeBoardRoomRoomQuestionOrder}
            />
            <QuestionBlock
                headerName={"Office Two/Meeting Room"}
                showYesNoAnswerOnly={false}
                checklist={props.officeTwoMeetingRoomQuestions}
                nestingList={["premisesHeadOfficeAudit", "officeTwoMeetingRoomQuestions"]}
                questionOrder={mainOfficeExecutiveOfficeMeetingRoomQuestionOrder}
            />
            <QuestionBlock
                headerName={"Executive Offices"}
                showYesNoAnswerOnly={false}
                checklist={props.executiveOfficesQuestions}
                nestingList={["premisesHeadOfficeAudit", "executiveOfficesQuestions"]}
                questionOrder={mainOfficeExecutiveOfficeMeetingRoomQuestionOrder}
            />
            <QuestionBlock
                headerName={"Main Kitchen"}
                showYesNoAnswerOnly={false}
                checklist={props.mainKitchenQuestion}
                nestingList={["premisesHeadOfficeAudit", "mainKitchenQuestion"]}
                questionOrder={mainOfficeKitchenQuestionOrder}
            />
            <QuestionBlock
                headerName={"Electrical"}
                showYesNoAnswerOnly={false}
                checklist={props.electricalQuestions}
                nestingList={["premisesHeadOfficeAudit", "electricalQuestions"]}
                questionOrder={mainOfficeElectricalQuestionOrder}
            />
            <QuestionBlock
                headerName={"Security"}
                showYesNoAnswerOnly={false}
                checklist={props.securityQuestions}
                nestingList={["premisesHeadOfficeAudit", "securityQuestions"]}
                questionOrder={mainOfficeSecurityQuestionOrder}
            />
        </React.Fragment>
    );
};

export default PremisesHeadOfficeAuditForm;
