import {VENUE_LIST_STORE, VenueListDispatchTypes} from "./VenueListActionTypes";
import {Dispatch} from "redux";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import RiskManagementApiModel from "../../apiModel/RiskManagementApiModel";
import {Venue} from "../../../api/rm";
import {showErrorToast} from "../../../utils/toastUtils";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nullifies the venue list store. */
export const nullifyVenueListStore = () => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>) => {
        dispatch({
            type: VENUE_LIST_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const getAllVenues = () => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                VENUE_LIST_STORE,
                dispatch,
                () => RiskManagementApiModel.getRmApi().listVenues(),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: VENUE_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const getAllVenuesForVenueDropdown = () => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>) => {
        try {
            dispatch({
                type: VENUE_LIST_STORE.LOADING,
                loading: true,
                error: null
            });
            const data = await getVenueList();
            dispatch({
                type: VENUE_LIST_STORE.SUCCESS,
                loading: false,
                error: null,
                data
            });

            return data;
        } catch (e: any) {
            dispatch({
                type: VENUE_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

async function getVenueList(): Promise<Venue[]> {
    try {
        const request = await RiskManagementApiModel.getRmApi().listVenues();
        return request.data;
    } catch (e: any) {
        showErrorToast("Could not get venue list");
        return [];
    }
}
