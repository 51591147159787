import React, {useEffect} from "react";
import {Audit, AuditType, Category, Venue} from "../../../../api/rm";
import CategoryDropdown from "../../../Dropdown/CategoryDropdown";
import {getEmptyCategory} from "../../../../store/category/actions/CategoryActions";
import {useDispatch} from "react-redux";
import {
    nullifyAuditStore,
    saveAuditToService,
    setAudit,
    setAuditType
} from "../../../../store/audit/actions/AuditActions";
import FormRow from "../../../Form/FormRow";
import VenueDropdown from "../../../Dropdown/VenueDropdown";
import {getEmptyVenue} from "../../../../store/venue/actions/VenueActions";
import FormHeader from "../../../Form/FormHeader";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {getAuditTypeFromString} from "../../../../utils/enumUtils";
import AuditFormContainer from "./Forms/General/AuditFormContainer";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import {Modal, useModal} from "pulse-modal";

const EditAuditForm = (props: Audit) => {
    const dispatch = useDispatch();
    const {isShown, toggle} = useModal();
    const history = useHistory();

    useEffect(() => {
        return () => {
            dispatch(nullifyAuditStore());
        };
    }, []);

    const onCategoryChanged = (incomingCategory: Category | undefined) => {
        const categoryId = incomingCategory ? incomingCategory.id : 0;
        dispatch(
            setAudit({
                ...props,
                categoryId
            })
        );
    };

    const onVenueChanged = (incomingVenue: Venue | undefined) => {
        const venueId = incomingVenue ? incomingVenue.id : 0;
        dispatch(
            setAudit({
                ...props,
                venueId
            })
        );
    };

    const renderOptionButtons = (key: string, audit: Audit): JSX.Element | undefined => {
        switch (key) {
            case AuditType.None:
            case AuditType.AuditFireSafety:
                return;
            default:
                return (
                    <div className="col-sm-3 report-button">
                        <MCButton
                            size={ButtonSize.ExtraLarge}
                            innerValue={getUiFriendlyText(key)}
                            className="w-100"
                            onClick={() => {
                                // Don't want to reset the form if we reselect
                                if (key === audit.type) return;
                                dispatch(setAuditType(getAuditTypeFromString(key)));
                            }}
                            colour={
                                key === audit.type
                                    ? ButtonColourOptions.Yellow
                                    : ButtonColourOptions.DarkBlue
                            }
                            disabled={props.id > 0}
                        />
                    </div>
                );
        }
    };

    const backToAuditList = () => {
        history.replace(routeNames.auditList.path);
    };

    const saveAudit = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveAuditToService(props));

        if (success) {
            backToAuditList();
        }
    };

    return (
        <React.Fragment>
            <div className="fadeIn mt-5 pt-3 mb-5">
                <FormHeader headerName={"Audit Details"} />
                <FormRow rowName="Author">
                    <p className="mb-0">{props.authorName}</p>
                </FormRow>
                <FormRow rowName="Audit Date">
                    <p className="mb-0">{formatUnixToDDMMYYYY(props.dateCreated)}</p>
                </FormRow>
                {props.id > 0 && props.complianceScore && (
                    <FormRow rowName="Score">
                        <p className="mb-0">{props.complianceScore}</p>
                    </FormRow>
                )}
                <FormRow rowName="Category" columnDetailClassName={"pl-0 pr-0"}>
                    <CategoryDropdown
                        item={{...getEmptyCategory(), id: props.categoryId}}
                        changeOption={onCategoryChanged}
                        searchable
                        clearable
                        disabled={props.id > 0}
                    />
                </FormRow>
                <FormRow rowName="Venue" columnDetailClassName={"pl-0 pr-0"}>
                    <VenueDropdown
                        item={{...getEmptyVenue(), id: props.venueId}}
                        changeOption={onVenueChanged}
                        searchable
                        clearable
                        disabled={props.id > 0}
                    />
                </FormRow>
                <FormHeader headerName={"Audit Type"} />
                <FormActionContainer>
                    <React.Fragment>
                        {Object.keys(AuditType).map((item: string) => {
                            return renderOptionButtons(item, props);
                        })}
                    </React.Fragment>
                </FormActionContainer>
                <AuditFormContainer {...JSON.parse(props.payload)} />
                <FormActionContainer>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue="Save"
                        onClick={saveAudit}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue="Cancel"
                        onClick={toggle}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </FormActionContainer>
                <Modal
                    isShown={isShown}
                    onClose={toggle}
                    modalSize={"sm"}
                    title={"Cancel edits..."}
                    bodyChildren={
                        <React.Fragment>
                            <div className="row ml-0 mr-0">
                                <p className="mb-0">
                                    Are you sure you want to return to the audit list? All unsaved
                                    progress will be lost!
                                </p>
                            </div>
                        </React.Fragment>
                    }
                    footerChildren={
                        <div className="row ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={backToAuditList}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
};

export default EditAuditForm;
