import {showErrorToast} from "./toastUtils";
import {FormErrors} from "./formUtils";

// eslint-disable-next-line @typescript-eslint/ban-types
export function validateCategoryVenueForm<T extends {name: string}>(
    form: T,
    formType: FormType
): FormErrors {
    let valid = true;
    const messages: string[] = [];
    if (form.name.length === 0) {
        valid = false;
        const message = `${formType} name cannot be blank.`;
        showErrorToast(message);
        messages.push(message);
    }
    if (form.name.length < 3) {
        valid = false;
        const message = `${formType} name must be more than 3 characters long.`;
        showErrorToast(message);
        messages.push(message);
    }
    return {valid, messages};
}

type FormType = "Venue" | "Category";
