import React from "react";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {AuditType} from "../../../../../../../api/rm";
import QuestionBlock from "../../General/QuestionBlock";

import {
    fireSafety5YearlyQuestionOrder,
    FiveYearFireSafetyAudit
} from "../../../../../../../store/audit/helpers/FireSafety/fiveYearFireSafetyAudit";

const FiveYearFireAudit = (props: FiveYearFireSafetyAudit) => {
    return (
        <React.Fragment>
            <QuestionBlock
                headerName={getUiFriendlyText(AuditType.AuditFiveYearlyFireSafety)}
                showYesNoAnswerOnly={true}
                nestingList={["fiveYearFireSafetyAudit"]}
                checklist={props}
                questionOrder={fireSafety5YearlyQuestionOrder}
            />
        </React.Fragment>
    );
};

export default FiveYearFireAudit;
