import {
    Answer,
    newQuestionWithAnswerAndCommentsAndDate,
    newSectionDetailItem,
    QuestionWithAnswerAndCommentsAndDate,
    SectionDetails
} from "../auditHelpers";

export interface AnnualFireSafetyAudit {
    fireExtinguisherServiceDetails: QuestionWithAnswerAndCommentsAndDate;
    annualCheckDetails: SectionDetails;
}

export const fireSafetyAnnualQuestionOrder: string[] = [
    "fireExtinguisherServiceDetails",
    "annualCheckDetails"
];

export function generateBlankAnnualFireSafetyAudit(): AnnualFireSafetyAudit {
    return {
        fireExtinguisherServiceDetails: newQuestionWithAnswerAndCommentsAndDate(
            "Fire extinguisher service?",
            0,
            Answer.No,
            "Service Date:"
        ),
        annualCheckDetails: newSectionDetailItem("Annual check comments/details")
    };
}
