import {Dispatch} from "redux";
import {STATS_STORE, StatsDispatchTypes} from "./StatsActionTypes";
import {StatsRequest} from "../../../api/rm";
import RiskManagementApiModel from "../../apiModel/RiskManagementApiModel";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nullifies the stats store. */
export const nullifyStatsStore = () => {
    return async (dispatch: Dispatch<StatsDispatchTypes>) => {
        dispatch({
            type: STATS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const getStatsForRiskManagement = (request: StatsRequest) => {
    return async (dispatch: Dispatch<StatsDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                STATS_STORE,
                dispatch,
                () => RiskManagementApiModel.getRmApi().getStatsRiskManagement(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: STATS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
