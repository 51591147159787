import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {Audit} from "../../../api/rm";

export const AUDIT_STORE = createStoreState("audit_store");

interface AuditLoading extends StoreServiceData<Audit> {
    type: typeof AUDIT_STORE.LOADING;
}

interface AuditError extends StoreServiceData<Audit> {
    type: typeof AUDIT_STORE.ERROR;
}

interface AuditSuccess extends StoreServiceData<Audit> {
    type: typeof AUDIT_STORE.SUCCESS;
}

export type AuditDispatchTypes = AuditLoading | AuditError | AuditSuccess;
