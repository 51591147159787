import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {AuditListResponse} from "../../../api/rm";

export const AUDIT_LIST_PAGED_STORE = createStoreState("audit_list_paged");

interface AuditListPagedLoading extends StoreServiceData<AuditListResponse> {
    type: typeof AUDIT_LIST_PAGED_STORE.LOADING;
}

interface AuditListPagedError extends StoreServiceData<AuditListResponse> {
    type: typeof AUDIT_LIST_PAGED_STORE.ERROR;
}

interface AuditListPagedSuccess extends StoreServiceData<AuditListResponse> {
    type: typeof AUDIT_LIST_PAGED_STORE.SUCCESS;
}

export type AuditListPagedDispatchTypes =
    | AuditListPagedLoading
    | AuditListPagedError
    | AuditListPagedSuccess;
