import React from "react";
import {
    PremisesTrainingAndStorageAudit,
    trainingAndStorageCommunalAreaQuestionOrder,
    trainingAndStorageElectricalQuestionOrder,
    trainingAndStorageKitchenQuestionOrder,
    trainingAndStorageLockerRoomQuestionOrder,
    trainingAndStorageOfficeQuestionOrder,
    trainingAndStorageSecurityQuestionOrder,
    trainingAndStorageTrainingRoomQuestionOrder
} from "../../../../../../../store/audit/helpers/Premises/premisesTrainingAndStorageAudit";
import FormHeader from "../../../../../../Form/FormHeader";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {AuditType} from "../../../../../../../api/rm";
import QuestionBlock from "../../General/QuestionBlock";

const PremisesTrainingAndSafetyAuditForm = (props: PremisesTrainingAndStorageAudit) => {
    return (
        <React.Fragment>
            <FormHeader
                headerName={getUiFriendlyText(AuditType.AuditPremisesTrainingStorageArea)}
            />
            <QuestionBlock
                headerName={"Communal Area"}
                showYesNoAnswerOnly={false}
                checklist={props.communalAreaQuestions}
                nestingList={["premisesTrainingStorageAreaAudit", "communalAreaQuestions"]}
                questionOrder={trainingAndStorageCommunalAreaQuestionOrder}
            />
            <QuestionBlock
                headerName={"Kitchen"}
                showYesNoAnswerOnly={false}
                checklist={props.kitchenQuestions}
                nestingList={["premisesTrainingStorageAreaAudit", "kitchenQuestions"]}
                questionOrder={trainingAndStorageKitchenQuestionOrder}
            />
            <QuestionBlock
                headerName={"Electrical"}
                showYesNoAnswerOnly={false}
                checklist={props.electricalQuestions}
                nestingList={["premisesTrainingStorageAreaAudit", "electricalQuestions"]}
                questionOrder={trainingAndStorageElectricalQuestionOrder}
            />
            <QuestionBlock
                headerName={"Security"}
                showYesNoAnswerOnly={false}
                checklist={props.securityQuestions}
                nestingList={["premisesTrainingStorageAreaAudit", "securityQuestions"]}
                questionOrder={trainingAndStorageSecurityQuestionOrder}
            />
            <QuestionBlock
                headerName={"Locker Rooms"}
                showYesNoAnswerOnly={false}
                checklist={props.lockerRoomQuestions}
                nestingList={["premisesTrainingStorageAreaAudit", "lockerRoomQuestions"]}
                questionOrder={trainingAndStorageLockerRoomQuestionOrder}
            />
            <QuestionBlock
                headerName={"Training Room One"}
                showYesNoAnswerOnly={false}
                checklist={props.trainingRoomOneQuestions}
                nestingList={["premisesTrainingStorageAreaAudit", "trainingRoomOneQuestions"]}
                questionOrder={trainingAndStorageTrainingRoomQuestionOrder}
            />
            <QuestionBlock
                headerName={"Training Room Two"}
                showYesNoAnswerOnly={false}
                checklist={props.trainingRoomTwoQuestions}
                nestingList={["premisesTrainingStorageAreaAudit", "trainingRoomTwoQuestions"]}
                questionOrder={trainingAndStorageTrainingRoomQuestionOrder}
            />
            {props.stockRoomQuestions && (
                <QuestionBlock
                    headerName={"Stock Room"}
                    showYesNoAnswerOnly={false}
                    checklist={props.stockRoomQuestions}
                    nestingList={["premisesTrainingStorageAreaAudit", "stockRoomQuestions"]}
                />
            )}
            {props.immersiveSuiteQuestions && (
                <QuestionBlock
                    headerName={"Immersive Suite"}
                    showYesNoAnswerOnly={false}
                    checklist={props.immersiveSuiteQuestions}
                    nestingList={["premisesTrainingStorageAreaAudit", "immersiveSuiteQuestions"]}
                />
            )}
            {props.medicineManagementQuestions && (
                <QuestionBlock
                    headerName={"Medicine Management Room"}
                    showYesNoAnswerOnly={false}
                    checklist={props.medicineManagementQuestions}
                    nestingList={[
                        "premisesTrainingStorageAreaAudit",
                        "medicineManagementQuestions"
                    ]}
                />
            )}
            <QuestionBlock
                headerName={"Office"}
                showYesNoAnswerOnly={false}
                checklist={props.officeQuestions}
                nestingList={["premisesTrainingStorageAreaAudit", "officeQuestions"]}
                questionOrder={trainingAndStorageOfficeQuestionOrder}
            />
        </React.Fragment>
    );
};

export default PremisesTrainingAndSafetyAuditForm;
