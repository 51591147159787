import React, {useEffect, useState} from "react";
import {pagedRequestConfig, RequestFilterProps} from "../../../Filters/helpers/filterHelpers";
import {HistoricAuditListRequest} from "../../../../api/rm";
import {useDispatch} from "react-redux";
import moment, {Moment} from "moment";
import {useHistory, useParams} from "react-router-dom";
import {useQuery} from "../../../Hooks/useQuery";
import {nullifyAuditListHistoricPagedStore} from "../../../../store/auditHistoricListPaged/actions/AuditListHistoricPagedActions";
import DatePicker from "react-datepicker";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";

const AuditListHistoricPagedFilters = (props: RequestFilterProps<HistoricAuditListRequest>) => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState<Moment>();
    const params: any = useParams();
    const history = useHistory();
    const [pageNumber, setPageNumber] = useState<number>(-1);
    const query = useQuery();

    useEffect(() => {
        const minDate = query.get("minHistoricDate");
        setStartDate(minDate ? moment.unix(+minDate) : moment().startOf("month"));

        const pageNum = query.get("pageNumHistoric");
        setPageNumber(pageNum ? +pageNum : 0);

        return () => {
            dispatch(nullifyAuditListHistoricPagedStore());
        };
    }, []);

    useEffect(() => {
        if (!startDate) return;
        if (pageNumber < 0) return;
        const request = buildAuditListHistoricPagedRequest(startDate, pageNumber);
        buildAuditListHistoricPagedUrlHistory(request);

        props.onRequestChanged(request);
    }, [startDate, pageNumber]);

    const buildAuditListHistoricPagedRequest = (
        incomingStartDate: Moment,
        incomingPageNumber?: number
    ): HistoricAuditListRequest => {
        const pageNum = incomingPageNumber ? incomingPageNumber : 0;
        const minDate = incomingStartDate
            ? incomingStartDate.clone().startOf("month").unix()
            : undefined;
        const maxDate = incomingStartDate
            ? incomingStartDate.clone().endOf("month").unix()
            : undefined;

        return {
            minDate,
            maxDate,
            auditId: +params.auditId,
            numPerPage: pagedRequestConfig.resultsPerPage,
            pageNum
        };
    };

    const buildAuditListHistoricPagedUrlHistory = (request: HistoricAuditListRequest) => {
        const search: string[] = [];

        if (request.minDate) {
            search.push(`minHistoricDate=${request.minDate}`);
        }

        if (request.maxDate) {
            search.push(`maxHistoricDate=${request.maxDate}`);
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (request.pageNum >= 0) {
            search.push(`pageNumHistoric=${request.pageNum}`);
        }

        history.push({
            search: search.join("&")
        });
    };

    const onDateChanged = (date: Date) => {
        setStartDate(moment(date));
    };
    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <div className="filter-item">
                    <h6>Month</h6>
                    <DatePicker
                        selected={startDate?.toDate()}
                        onChange={onDateChanged}
                        showMonthYearPicker
                        dateFormat="MMMM yyyy"
                        portalId="root-portal"
                        customInput={<DatePickerInputButton />}
                        placeholderText="Select start date"
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default AuditListHistoricPagedFilters;
