import {STAFF_LIST_STORE_STATE, StaffListDispatchTypes} from "./StaffListActionTypes";
import {Dispatch} from "redux";
import StaffApiModel from "../../apiModel/StaffApiModel";
import {showErrorToast} from "../../../utils/toastUtils";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Clears the state of the store. */
export const nullifyStaffListStore = () => {
    return async (dispatch: Dispatch<StaffListDispatchTypes>) => {
        dispatch({
            type: STAFF_LIST_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Gets all staff members in the MC database */
export const getAllMedicareStaffMembers = () => {
    return async (dispatch: Dispatch<StaffListDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                STAFF_LIST_STORE_STATE,
                dispatch,
                () => StaffApiModel.getUsersApi().getAllUsers(),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: STAFF_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
            showErrorToast(e);
        }
    };
};
