import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {Category} from "../../../api/rm";

export const CATEGORY_STORE = createStoreState("category_store");

interface CategoryLoading extends StoreServiceData<Category> {
    type: typeof CATEGORY_STORE.LOADING;
}
interface CategoryError extends StoreServiceData<Category> {
    type: typeof CATEGORY_STORE.ERROR;
}
interface CategorySuccess extends StoreServiceData<Category> {
    type: typeof CATEGORY_STORE.SUCCESS;
}

export type CategoryDispatchTypes = CategoryLoading | CategoryError | CategorySuccess;
