import React from "react";

const RiskAssessmentTableHeader = () => {
    return (
        <thead>
            <tr className="mc-table-header">
                <th align="center" className="mc-table-header-item p-3">
                    Risk Description
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    Cause/Source of Identified Risk
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    Date Opened
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    Last Reviewed
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    Time Scale For Review
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    Review Date
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    Risk Category
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    Risk Owner
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    {generateMatrixRatingTableHeader("Initial Rating")}
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    Existing Controls
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    {generateMatrixRatingTableHeader("Current Rating")}
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    Further Actions
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    {generateMatrixRatingTableHeader("Target Rating")}
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    Additional Comments
                </th>
                <th align="center" className="mc-table-header-item p-3">
                    Actions
                </th>
            </tr>
        </thead>
    );
};

export default RiskAssessmentTableHeader;

function generateMatrixRatingTableHeader(headingName: string): JSX.Element {
    return (
        <React.Fragment>
            <h6 className="mb-0">{headingName}</h6>
            <table>
                <thead>
                    <tr className="mc-table-header">
                        <th className="mc-table-header-item">S</th>
                        <th className="mc-table-header-item">L</th>
                        <th className="mc-table-header-item">T</th>
                    </tr>
                </thead>
            </table>
        </React.Fragment>
    );
}
