import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {Venue} from "../../../api/rm";

export const VENUE_STORE = createStoreState("venue_store");

interface VenueStoreLoading extends StoreServiceData<Venue> {
    type: typeof VENUE_STORE.LOADING;
}
interface VenueStoreError extends StoreServiceData<Venue> {
    type: typeof VENUE_STORE.ERROR;
}
interface VenueStoreSuccess extends StoreServiceData<Venue> {
    type: typeof VENUE_STORE.SUCCESS;
}

export type VenueDispatchTypes = VenueStoreError | VenueStoreSuccess | VenueStoreLoading;
